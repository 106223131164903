import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_US from './en_US';
import pt_BR from './pt_BR';
import fr_CA from './fr_CA';

const resources = {
	en_US: { translation: en_US },
	pt_BR: { translation: pt_BR },
	fr_CA: { translation: fr_CA },
};


i18n
	.use(initReactI18next)
	.init({
		resources,
		interpolation: {
			escapeValue: false,
		},
		lng: 'en_US'
	})

export default i18n;