"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var assessmentState = {
  step: 0
};
var _default = assessmentState;
exports["default"] = _default;