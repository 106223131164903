import api from '.';

export function getTimes(locationID, day = 'today') {
	const config = {
		params: {
			show_avl_appt_from: day,
			show_avl_appt_to: day,
		},
	};

	return api.get(`/location/${locationID}`, config)
}

export function createAppointment(appointment) {
	return api.post(`/appointment`, appointment);
}

export function editAppointment(id, appointment) {
	return api.put(`/appointment/${id}`, appointment)
}

export function deleteAppointment(id) {
	return api.delete(`/appointment/${id}`);
}

export function getApptInfoFromToken(token) {
	return api.get('/appointment', { headers: { 'X-REPAIRQ-TOKEN': token } });
}

export default {
	getTimes,
	createAppointment,
	deleteAppointment,
	getApptInfoFromToken,
}