import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LocationItem from './LocationItem';
import './LocationList.scss';

export default function LocationList(props) {
	const i18n = useTranslation();
	const { SETTINGS, list, updateLoc, onSelectedLocation, color, hasShop, setCoordinates, setAddress } = props;

	const clickRefresh = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setCoordinates(null);
		setAddress('')
	}

	return (<>
		{hasShop ? (
			<div className='main-location-list'>
				{list.map((loc) => (
					<LocationItem
						{...loc}
						key={loc.id}
						updateLoc={updateLoc}
						onSelected={onSelectedLocation}
						color={color}
						displayName={SETTINGS.locations?.displayName}
						customStyle={SETTINGS?.theme}
					/>))}
			</div>
		) : (
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '75%',
				margin: '2rem',
			}} >
				<i className='fas fa-exclamation-circle' style={{ fontSize: '3rem' }} />
				<p>{i18n.t('location.no_locations_found', {
					distance: SETTINGS.locations.distance,
					distance_unit: SETTINGS.locations.distance_unit
				})}</p>
				<Trans i18nkey={'location.please_update'}>
					<p>Please update your location or <a href='#'
						style={{ color: SETTINGS.theme.color.primary, textDecoration: 'none' }}
						onClick={(e) => clickRefresh(e)} data-testid='refresh-button'
					> click here </a>	to refresh</p>
				</Trans>
			</div>
		)
		}
	</>
	);
}
