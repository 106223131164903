import { faCalendarAlt, faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, InfoCard, NAVIGATION_ACTION, PageContainer, Title, Breadcrumbs, Description } from '@qsite/components';
import { appointmentActions, appSettingsActions, deviceActions, locationActions, ticketActions, useStore, customerInformationActions } from '@qsite/services';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES, breadcrumbsNavigationConfig } from '../../utils/routesConfig';
import '../Pages.scss';
import './Appointment.scss';
import { LangContext } from '../../App';
import { useLocation } from 'react-router-dom';
import { getApptInfoFromToken } from '../../services/api/appointment';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/en';

export default function AppointmentConfirmed() {
	const location = useLocation();
	const [state, dispatch] = useStore();
	const i18n = useTranslation();
	const displayBreadcrumbs = Boolean(state.qsiteSettings.theme?.breadcrumbs ?? false);
	const { appLang, mapLanguages } = useContext(LangContext);
	const appointmentSettings = state.qsiteSettings?.appointments;
	const customStyle = state.qsiteSettings?.theme;

	useEffect(() => {
		mapLanguages(state.qsiteSettings);

		const token = new URLSearchParams(location.search).get('token');

		if (token !== null) {
			getApptInfoFromToken(token).then(res => {
				let {
					date_scheduled,
					device,
					appointment_id,
					location,
					tickets,
					appointmentWindow,
					customer
				} = res.data.data.appointment;
				let locale = appLang === 'fr_CA' ? 'fr-ca' : 'en';

				// Note: the date_scheduled string from the response is not on the correct ISO format
				// This leads to a bug in Safari because the date can't be parsed by the ES standard it uses
				// That's why the string is being manipulated like this before instantiating the object
				const day = dayjs(date_scheduled).locale(locale).format('ddd, MMM DD YYYY');
				const time = date_scheduled.slice(date_scheduled.indexOf('T') + 1);

				if (appointmentWindow) {
					appointmentWindow.start = timesParser(appointmentWindow?.start);
					appointmentWindow.end = timesParser(appointmentWindow?.end);
				}

				dispatch(appointmentActions.setAppointment({
					day,
					time,
					rawTime: date_scheduled,
					appointment_id,
					location,
					timeWindow: appointmentWindow
				}));
				dispatch(locationActions.setLocation(location));
				dispatch(ticketActions.setTicketInformation({id: tickets[0].id}));
				dispatch(customerInformationActions.setCustomerInformation({
					first_name: customer?.first_name,
					last_name: customer?.last_name,
					wl_customer_id: customer?.wl_customer_id,
					pri_phone: customer?.pri_phone,
					email: customer?.email,
					address: {
						address1: customer?.address1,
						address2: customer?.address2,
						state: customer?.state,
						zip: customer?.zip,
						city: customer?.city,
						country: customer?.country,
					}
				}));
				dispatch(deviceActions.setDevice(device));
			})
			.catch(error => console.error({ error }));
		}
	}, [dispatch, location.search, appLang]);

	const breadcrumb = 	[
		{ name: i18n.t('breadcrumbs.location'), pathToNavigate: APP_ROUTES.LOCATION },
		{ name: i18n.t('breadcrumbs.identification'), pathToNavigate: APP_ROUTES.DEVICE_ID },
		{ name: i18n.t('breadcrumbs.contact'), pathToNavigate: APP_ROUTES.CUSTOMER },
		{ name: i18n.t('breadcrumbs.quote'), pathToNavigate: APP_ROUTES.QUOTE },
		{ name: i18n.t('breadcrumbs.appointment'), pathToNavigate: APP_ROUTES.APPOINTMENT },
		{ name: i18n.t('breadcrumbs.confirmation'), pathToNavigate: APP_ROUTES.APPOINTMENT_CONFIRMED },
	]

	function timesParser(timeString) {
		let H = +timeString.substr(0, 2);
		let h = H % 12 || 12;
		let ampm = (H < 12 || H === 24) ? ' am' : ' pm';
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}

	function handleGetDirectionsClick(e) {
		const googleMapsUrl = `https://www.google.com/maps/place/${state.location.address1}+${state.location.city}+${state.location.state}`;
		e.preventDefault();
		e.stopPropagation();
		return window.open(googleMapsUrl, '_blank');
	}

	function handleChangeAppointment(e) {
		e.preventDefault();
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT, method: NAVIGATION_ACTION.PUSH }))
	}

	function handleGetAnotherQuote() {
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.LOCATION, method: NAVIGATION_ACTION.PUSH }))
	}

	function handleCancelAppointment() {
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT_CANCELLATION, method: NAVIGATION_ACTION.PUSH }))
	}

	return (
		<PageContainer flex customStyle={customStyle}>
			{ displayBreadcrumbs &&
				(
					<Breadcrumbs
						type={'navigation'}
						crumbs={breadcrumb}
						navigationConfig={breadcrumbsNavigationConfig}
						translatedKeys={{ ariaLabel: i18n.t('general.breadcrumbs_aria_label') }}
						customStyle={customStyle}
					/>
				)
			}
			<Title title={appointmentSettings?.confirmation?.title ||i18n.t('appointmentConfirmed.title')} customStyle={customStyle} />
			<Description customStyle={customStyle}>
			<Grid gridTemplateColumns='1fr 1fr' mobileGridTemplateColumns='1fr' gridGap='0px' gridWidth='500px'>
				<InfoCard
					title={i18n.t('appointmentConfirmation.day_time')}
					titleTestId='appointment-info-card'
					customStyle={customStyle}
				>
					<p>{state.appointment.day} at {state.appointment.time}</p>
					<div className='change-information-container'>
						<FontAwesomeIcon icon={faCalendarAlt} />
						<a href="#" onClick={(e) => handleChangeAppointment(e)}>{i18n.t('appointmentConfirmation.change_day_time')}</a>
					</div>
				</InfoCard>
				<InfoCard
					title={i18n.t('appointmentConfirmation.contact_information')}
					titleTestId='contact-information-info-card'
					customStyle={customStyle}
				>
					<p>{state.customerInformation.first_name} {state.customerInformation.last_name}</p>
					<p>{state.customerInformation.pri_phone}</p>
					<p>{state.customerInformation.email}</p>
				</InfoCard>
				<InfoCard
					title={i18n.t('appointmentConfirmation.location')}
					titleTestId='location-info-card'
					customStyle={customStyle}
				>
					<p>{state.location.short_name}</p>
					<p>{state.location.address1}</p>
					<p>{state.location.city}, {state.location.state}</p>
					<p>{state.location.zip}</p>
					<div className='change-information-container'>
						<FontAwesomeIcon icon={faMapMarkerAlt} />
						<a href="#" onClick={(e) => handleGetDirectionsClick(e)} >{i18n.t('appointmentConfirmation.get_directions')}</a>
					</div>
				</InfoCard>
				<InfoCard
					title={i18n.t('appointmentConfirmation.device')}
					titleTestId='device-info-card'
					customStyle={customStyle}
				>
					<p>{state.device.parent?.name_friendly || state.device.parent?.name || state.device.name}</p>
				</InfoCard>
			</Grid>
			<div className='confirmation-buttons'>
				<Button
					buttonTheme='light'
					dataTestId='cancel-appointment-button'
					label={i18n.t('appointmentConfirmed.cancel_appointment')}
					onClick={handleCancelAppointment}
					customStyle={customStyle}
				/>
				{state.appSettings.initialLoad.path === APP_ROUTES.TICKET ?
					<Button
						dataTestId='view-summary-button'
						label={i18n.t('appointmentConfirmed.view_summary_button_label')}
						onClick={() => { dispatch(appSettingsActions.setProgress({ path: state.appSettings.initialLoad.path + state.appSettings.initialLoad.searchParam, method: NAVIGATION_ACTION.PUSH })) }}
						customStyle={customStyle}
					/> :
					<Button
						dataTestId='get-another-quote-button'
						label={i18n.t('appointmentConfirmed.get_another_quote_button_label')}
						onClick={handleGetAnotherQuote}
						customStyle={customStyle}
					/>}
			</div>
			</Description>
		</PageContainer>
	)
}