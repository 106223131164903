import { Description, PageContainer, Title } from '@qsite/components';
import React, { useEffect } from 'react';
import { useStore } from '@qsite/services';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import '../Pages.scss';

export default function Maintenance() {
	const i18n = useTranslation();
	const history = useHistory();
	const [state, ] = useStore();
	const { theme: customStyle } = state.qsiteSettings;

	useEffect(() => {
		history.push('/');
	}, [history])

	return (
		<PageContainer flex>
			<Title title={i18n.t('maintenance.title')} customStyle={customStyle} />
			<Description>{i18n.t('maintenance.description')}</Description>
		</PageContainer>
	)
}