"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mirageServer = mirageServer;

var _miragejs = require("miragejs");

function mirageServer(_ref) {
  var customMirageRoutes = _ref.customMirageRoutes,
      seed = _ref.seed,
      _ref$environment = _ref.environment,
      environment = _ref$environment === void 0 ? 'development' : _ref$environment;
  var server = new _miragejs.Server({
    environment: environment,
    urlPrefix: process.env.REACT_APP_API_URL,
    models: {
      device: _miragejs.Model
    },
    seeds: function seeds(server) {
      seed && server.db.createCollection('devices', seed);
    },
    routes: function routes() {
      var _this = this;

      customMirageRoutes.forEach(function (_ref2) {
        var method = _ref2.method,
            path = _ref2.path,
            response = _ref2.response,
            isActive = _ref2.isActive;
        return isActive && _this[method](path, response);
      }); // * DELETE - User Session

      this.passthrough('/session', {
        success: true
      }); // * Generic Passthrough

      this.passthrough();
    }
  });
  return server;
}

;