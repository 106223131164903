import { Breadcrumbs, CollapsibleText, Description, NAVIGATION_ACTION, PageContainer, Tile, TileGrid, Title } from '@qsite/components';
import { appSettingsActions, deviceActions, locationActions, useStore } from '@qsite/services';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtherDeviceImg from '../../../assets/other-device-icon.png';
import { getCatalogItems, getCompatibleServices } from '../../../services/api/device';
import { iconFinder } from '../../../utils/iconFinder';
import { APP_ROUTES, breadcrumbsNavigationConfig } from '../../../utils/routesConfig';
import '../../Pages.scss';
import { useValidation } from './useValidation';
import { useHistory, useParams } from 'react-router-dom';
import { getLocationById } from '../../../services/api/location';
import { LangContext } from '../../../App';

export default function DeviceIdentification() {
	// context hook
	const { onChangeAppLang, isLangChanged, mapLanguages } = useContext(LangContext);

  // react router hooks
	const { locationId } = useParams();
	const history = useHistory();

	const [currentOptions, setCurrentOptions] = useState([]);
	const [catalogStep, setCatalogStep] = useState(0);
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [isDeviceParentItem, setIsDeviceParentItem] = useState(false);
	const [parentItemDevice, setParentItemDevice] = useState({});
	const [state, dispatch] = useStore();
	const i18n = useTranslation();
	const { isResponseValid, isOnMakeCatalogStep, isCategory, isCatalogItem, isDevice, isCompatibleService } = useValidation();

	const locID = locationId || state.location.id || 1;
	const { category, title, description } = state.qsiteSettings.request_types.repair;
	const { theme: customStyle } = state.qsiteSettings;
	const tradeInGrades = state.qsiteSettings.trade_in?.grades ?? [];
	const displayBreadcrumbs = Boolean(state.qsiteSettings.theme?.breadcrumbs ?? false);

	const breadcrumb = 	[
			{ name: i18n.t('breadcrumbs.location'), pathToNavigate: APP_ROUTES.LOCATION },
			{ name: i18n.t('breadcrumbs.identification'), pathToNavigate: APP_ROUTES.DEVICE_ID },
		]

	async function onClickChangeOption(clickedOptionId, parentItemDevice) {
		// clicked on "Other device"
		if (clickedOptionId === -1) {
			dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.REJECTED, method: NAVIGATION_ACTION.PUSH }));
			return;
		}

		const selectedOption = currentOptions.find(option => option.id === clickedOptionId);

		let newOptions = [];
		let tempStep = catalogStep;
		if(breadcrumbs.length > 0 && catalogStep == 0) { //When we use breadcrumbs to navigate back to the first step
			tempStep = 1; 
		}
		if (isOnMakeCatalogStep(selectedOption, tempStep)) { // Add "other" option
			let optionsType = '';			
			if (selectedOption.catalog_items.length > 0) {
				optionsType = 'catalog_items';
				newOptions = newOptions.concat(selectedOption[optionsType]);
			}
			
			if (selectedOption.categories.length > 0) {
				optionsType = 'categories';
				newOptions = newOptions.concat(selectedOption[optionsType]);
			} 

			setParentItemDevice(selectedOption);
		} else if (isCategory(selectedOption)) {
			newOptions = selectedOption.categories;
			setIsDeviceParentItem(true);
			setParentItemDevice(selectedOption);
		} else if (isCatalogItem(selectedOption)) {
			newOptions = selectedOption.catalog_items;
			setParentItemDevice({});
		} else if (isDevice(selectedOption)) {
			setParentItemDevice(selectedOption);
			let selectedDevice = {
				id: selectedOption.id,
				name: selectedOption.name_friendly || selectedOption.name,
				sku: selectedOption.sku,
				grades: tradeInGrades,
				parentId: isDeviceParentItem ? selectedOption.id : state.device.parentId,
				parent: parentItemDevice,
				isPriceAdvertised: selectedOption.is_price_advertised,
				advertisedPrice: selectedOption.advertised_price,
			};

			dispatch(deviceActions.setDevice(selectedDevice));

			setIsDeviceParentItem(false);

			// Compatible Services
			// In case this feature is on, we check for services via API call
			// And we treat some edge cases where the array is empty or it throws an error
			if (state.qsiteSettings.compatible_service.active === '0') {
				dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.CUSTOMER, method: NAVIGATION_ACTION.PUSH }));
				return;
			}

			if (state.qsiteSettings.compatible_service.active === '1') {
				const result = await getCompatibleServices({ locationID: state.location.id, catalogItemID: selectedDevice.id, filter: state.qsiteSettings.compatible_service?.filter ?? '' })

				if (result.response?.statusText.includes('Compatibility not found')) {
					dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.CUSTOMER, method: NAVIGATION_ACTION.PUSH }));
					return
				}

				if (isResponseValid(result)) {
					newOptions = result.data.data.catalogItem
				} else {
					dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.CUSTOMER, method: NAVIGATION_ACTION.PUSH }));
					return;
				}
			}

		} else if (isCompatibleService(selectedOption)) {
			dispatch(deviceActions.setDevice({ repairOption: selectedOption }));
			dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.CUSTOMER, method: NAVIGATION_ACTION.PUSH }));
			return;
		}

		setBreadcrumbs(prevState => [...prevState, selectedOption]);
		setCurrentOptions(newOptions);
		setCatalogStep(step => step + 1);
	}

	useEffect(() => {
		// languages setup across the app
		mapLanguages(state.qsiteSettings);
	}, []);

	useEffect(() => {
		// locationId segment param validation
		if (locationId) {
			getLocationById(locationId).then((res) => {
				// set location state
				dispatch(locationActions.setLocation(res.data.data.location));
				// redirect to device component
				history.push(APP_ROUTES.DEVICE_ID);
			})
			.catch((err) => {
				console.error(err);
			});
		}

		let isMounted = true;
		if (category != 1) {
			getCatalogItems(category, locID)
			.then((response) => {
				if (isMounted) {
					setCurrentOptions(response.data?.data?.categories?.concat(response.data?.data?.catalog_items));
				}
			})
			.catch(error => console.error({ error }));
		}

		return () => {
			setCurrentOptions([]);
			isMounted = false;
		}
	}, [category, locID, state.location, locationId]);

	function renderCategoryDescription() {
		if (breadcrumbs.length > 0) {
			const categoryParent = breadcrumbs[breadcrumbs.length - 1];
			if (!_.isEmpty(categoryParent.short_description) && !_.isEmpty(categoryParent.description)) {
				return (
					<CollapsibleText
						shortDescription={categoryParent.short_description}
						description={categoryParent.description}
					/>
				);
			} else if (!_.isEmpty(categoryParent.short_description)) {
				return (
					<Description customStyle={customStyle}>{categoryParent.short_description}</Description>
				);
			} else if (!_.isEmpty(categoryParent.description)) {
				return <Description customStyle={customStyle}>{categoryParent.description}</Description>;
			}
		}
		return <div />
	}

	return (
		<PageContainer flex customStyle={customStyle}>
			{ displayBreadcrumbs &&
				(
					<Breadcrumbs
						type={'navigation'}
						crumbs={breadcrumb}
						navigationConfig={breadcrumbsNavigationConfig}
						translatedKeys={{ ariaLabel: i18n.t('general.breadcrumbs_aria_label') }}
						assessmentStep={state.assessment.step}
						customStyle={customStyle}
					/>
			 	)
			}
			<Title id='title' title={title || i18n.t('deviceIdentification.identify_device')} customStyle={customStyle} />
			<Description customStyle={customStyle}>{description || i18n.t('deviceIdentification.identify_device_desc')}</Description>
			{renderCategoryDescription()}
			{ displayBreadcrumbs &&
				(
					<Breadcrumbs
						type={'hierarchic'}
						crumbs={breadcrumbs}
						hierarchicOptions={{ setBreadcrumbs, setCatalogStep, setCurrentOptions }}
						translatedKeys={{ ariaLabel: i18n.t('general.breadcrumbs_aria_label') }}
						customStyle={customStyle}
					/>
				)
			}
			<TileGrid ariaLabelledBy='title' customStyle={customStyle}>
				{currentOptions?.map(option =>
					<li key={option.id}>
						<Tile
							dataTestId='tile-option'
							option={option}
							onClick={(e) => onClickChangeOption(e, parentItemDevice)}
							icon={iconFinder(option)}
							customStyle={customStyle}
						/>
					</li>
				)}
			</TileGrid>
		</PageContainer>
	)
}
