"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setDevice = function setDevice(device) {
  return {
    type: _types["default"].SET_DEVICE,
    payload: device
  };
};

var resetDevice = function resetDevice() {
  return {
    type: _types["default"].RESET_DEVICE
  };
};

var restoreInitialGrades = function restoreInitialGrades() {
  return {
    type: _types["default"].RESTORE_INITIAL_GRADES
  };
};

var resetAssessment = function resetAssessment() {
  return {
    type: _types["default"].RESET_ASSESSMENT
  };
};

var _default = {
  setDevice: setDevice,
  resetDevice: resetDevice,
  resetAssessment: resetAssessment,
  restoreInitialGrades: restoreInitialGrades
};
exports["default"] = _default;