"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setQsiteSettings = function setQsiteSettings(qsiteSettings) {
  return {
    type: _types["default"].SET_QSITE_SETTINGS,
    payload: qsiteSettings
  };
};

var setValidationRules = function setValidationRules(validationRules) {
  return {
    type: _types["default"].SET_VALIDATION_RULES,
    payload: validationRules
  };
};

var _default = {
  setQsiteSettings: setQsiteSettings,
  setValidationRules: setValidationRules
};
exports["default"] = _default;