// Keep routes in order
export const APP_ROUTES = {
	LOCATION: '/location',
	DEVICE_ID: '/deviceID',
	REJECTED: '/rejected',
	CUSTOMER: '/customer',
	QUOTE: '/quote',
	APPOINTMENT: '/appointment',
	APPOINTMENT_CONFIRMATION: '/appointment/confirmation',
	APPOINTMENT_CONFIRMED: '/appointment/confirmed',
	APPOINTMENT_CANCELLATION: '/appointment/cancellation',
	APPOINTMENT_CANCELLED: '/appointment/cancelled',
	TICKET: '/ticket',
	TICKET_LOOKUP: '/lookup',
}

// The object key references the route trying to be accessed via url change
// The array items represent the progress state persisted on store that are allowed to go back to that route
// Empty array "[]" indicates that the route is open; array with "null" indicates that no route can navigate there by url change
const ROUTES_ACCESS = {
	[APP_ROUTES.LOCATION]: [],
	[APP_ROUTES.DEVICE_ID]: [APP_ROUTES.LOCATION, APP_ROUTES.CUSTOMER],
	[APP_ROUTES.REJECTED]: [APP_ROUTES.DEVICE_ID],
	[APP_ROUTES.CUSTOMER]: [APP_ROUTES.DEVICE_ID, APP_ROUTES.QUOTE],
	[APP_ROUTES.QUOTE]: [APP_ROUTES.CUSTOMER, APP_ROUTES.APPOINTMENT],
	[APP_ROUTES.APPOINTMENT]: [APP_ROUTES.QUOTE, APP_ROUTES.APPOINTMENT_CONFIRMATION, APP_ROUTES.APPOINTMENT_CANCELLED, APP_ROUTES.TICKET],
	[APP_ROUTES.APPOINTMENT_CONFIRMATION]: [APP_ROUTES.APPOINTMENT, APP_ROUTES.APPOINTMENT_CONFIRMED],
	[APP_ROUTES.APPOINTMENT_CONFIRMED]: [APP_ROUTES.APPOINTMENT_CONFIRMATION, APP_ROUTES.APPOINTMENT_CANCELLATION],
	[APP_ROUTES.APPOINTMENT_CANCELLATION]: [APP_ROUTES.APPOINTMENT_CONFIRMED, APP_ROUTES.TICKET, APP_ROUTES.APPOINTMENT_CANCELLED],
	[APP_ROUTES.APPOINTMENT_CANCELLED]: [APP_ROUTES.APPOINTMENT_CANCELLATION, APP_ROUTES.APPOINTMENT],
	[APP_ROUTES.TICKET]: [],
	[APP_ROUTES.TICKET_LOOKUP]: []
}

export const ROUTES_RESET_FUNCTION = {
	[APP_ROUTES.LOCATION]: 'resetToInitialState',
	[APP_ROUTES.DEVICE_ID]: 'resetToDevice',
	[APP_ROUTES.REJECTED]: null,
	[APP_ROUTES.CUSTOMER]: null,
	[APP_ROUTES.QUOTE]: null,
	[APP_ROUTES.APPOINTMENT]: null,
	[APP_ROUTES.APPOINTMENT_CONFIRMATION]: null,
	[APP_ROUTES.APPOINTMENT_CONFIRMED]: null,
	[APP_ROUTES.APPOINTMENT_CANCELLATION]: null,
	[APP_ROUTES.APPOINTMENT_CANCELLED]: null,
	[APP_ROUTES.TICKET]: null,
	[APP_ROUTES.TICKET_LOOKUP]: null
}

export const confirmModalResolver = (routeTo, routeFrom) => {
	
	return 'NO_SHOW';
}

const hasRouteAccess = (routeFrom, routeTo) => {
	return ROUTES_ACCESS[routeTo].length === 0 || ROUTES_ACCESS[routeTo].indexOf(routeFrom) !== -1
};

export const breadcrumbsNavigationConfig = {
	routesResetFunction: ROUTES_RESET_FUNCTION,
	confirmModalResolver
}

export default { APP_ROUTES, ROUTES_ACCESS, ROUTES_RESET_FUNCTION, confirmModalResolver, hasRouteAccess, breadcrumbsNavigationConfig }
