"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_DEVICE: 'SET_DEVICE',
  RESET_DEVICE: 'RESET_DEVICE',
  RESTORE_INITIAL_GRADES: 'RESTORE_INITIAL_GRADES',
  RESET_ASSESSMENT: 'RESET_ASSESSMENT'
};
exports["default"] = _default;