"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var appSettingsState = {
  progress: {
    path: '/',
    method: '',
    state: null,
    searchParam: null
  },
  error: {
    showErrorScreen: false,
    errorResponse: null
  },
  isMaintenance: false,
  applicationModal: {
    modalType: 0,
    breadcrumbTo: null
  },
  initialLoad: {
    path: null,
    searchParam: null
  }
};
var _default = appSettingsState;
exports["default"] = _default;