import * as Yup from 'yup';
import i18n from '../../i18n';

export const validationSchemaDefault = Yup.object({
	firstName: Yup.string()
		.matches(/^[a-zA-Z \- ' , .\u00C0-\u00FF\s]*$/g, i18n.t('validations.only_letters'))
		.matches(/[\S]/g, i18n.t('validations.invalid_only_whitespace'))
		.required(i18n.t('validations.required')),
	lastName: Yup.string()
		.matches(/^[a-zA-Z \- ' , .\u00C0-\u00FF\s]*$/g, i18n.t('validations.only_letters'))
		.matches(/[\S]/g, i18n.t('validations.invalid_only_whitespace'))
		.required(i18n.t('validations.required')),
	email: Yup.string()
		.email(i18n.t('validations.invalid_email'))
		.required(i18n.t('validations.required')),
	phoneNumber: Yup.string()
		.matches(/^[0-9 -]*$/g, i18n.t('validations.only_numbers'))
		.length(12, i18n.t('validations.must_be_length', { length: 10 }))		
		.required(i18n.t('validations.required')),
	problemDescription: Yup.string()
		.matches(/^[A-Za-z0-9 _ . , ; : ! \- ( ) ' " % $ # @\u00C0-\u00FF]*$/g, i18n.t('validations.required'))
		.matches(/[\S]/g, i18n.t('validations.invalid_only_whitespace'))
		.required(i18n.t('validations.required')),
	receiveNotifications: Yup.string()
		.required(i18n.t('validations.required')),
	receiveMarketing: Yup.string()
		.required(i18n.t('validations.required')),
})

export const validationSchemaPhoneNumberLength = Yup.object({
	firstName: Yup.string()
		.matches(/^[a-zA-Z \- ' , .\u00C0-\u00FF\s]*$/g, i18n.t('validations.only_letters'))
		.matches(/[\S]/g, i18n.t('validations.invalid_only_whitespace'))
		.required(i18n.t('validations.required')),
	lastName: Yup.string()
		.matches(/^[a-zA-Z \- ' , .\u00C0-\u00FF\s]*$/g, i18n.t('validations.only_letters'))
		.matches(/[\S]/g, i18n.t('validations.invalid_only_whitespace'))
		.required(i18n.t('validations.required')),
	email: Yup.string()
		.email(i18n.t('validations.invalid_email'))
		.required(i18n.t('validations.required')),
	phoneNumber: Yup.string()
		.matches(/^[0-9 -]*$/g, i18n.t('validations.only_numbers'))
		.min(1, i18n.t('validations.min_length', { length: 1 }))
		.required(i18n.t('validations.required')),
	problemDescription: Yup.string()
		.matches(/^[A-Za-z0-9 _ . , ; : ! \- ( ) ' " % $ # @\u00C0-\u00FF]*$/g, i18n.t('validations.required'))
		.matches(/[\S]/g, i18n.t('validations.invalid_only_whitespace'))
		.required(i18n.t('validations.required')),
	receiveNotifications: Yup.string()
		.required(i18n.t('validations.required')),
	receiveMarketing: Yup.string()
		.required(i18n.t('validations.required')),
})
