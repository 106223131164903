"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_QSITE_SETTINGS: 'SET_QSITE_SETTINGS',
  SET_VALIDATION_RULES: 'SET_VALIDATION_RULES'
};
exports["default"] = _default;