import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Description, Grid, InfoCard, NAVIGATION_ACTION, PageContainer, Title, Breadcrumbs } from '@qsite/components';
import { appSettingsActions, useStore } from '@qsite/services';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES, breadcrumbsNavigationConfig } from '../../utils/routesConfig';
import '../Pages.scss';
import './Quote.scss';
import { LangContext } from '../../App';

export default function Quote() {
	const i18n = useTranslation();
	const [state, dispatch] = useStore();
	const { location } = state.ticket;
	const { qsiteSettings, device } = state;
	const [showPrices] = useState(qsiteSettings.quotes.show_prices === "1" && Boolean(Number(device?.isPriceAdvertised) && device?.advertisedPrice > 0));
	const displayBreadcrumbs = Boolean(state.qsiteSettings.theme?.breadcrumbs ?? false);
	const { mapLanguages } = useContext(LangContext);
	const hasParentDevice = Object.keys(device.parent).length > 0;
	const customStyle = qsiteSettings.theme;

	function handleGetDirectionsClick(e) {
		const googleMapsUrl = `https://www.google.com/maps/place/${state.location.address1}+${state.location.city}+${state.location.state}`;
		e.preventDefault();
		e.stopPropagation();
		return window.open(googleMapsUrl, '_blank');
	}

	const breadcrumb = 	[
		{ name: i18n.t('breadcrumbs.location'), pathToNavigate: APP_ROUTES.LOCATION },
		{ name: i18n.t('breadcrumbs.identification'), pathToNavigate: APP_ROUTES.DEVICE_ID },
		{ name: i18n.t('breadcrumbs.contact'), pathToNavigate: APP_ROUTES.CUSTOMER },
		{ name: i18n.t('breadcrumbs.quote'), pathToNavigate: APP_ROUTES.QUOTE },
	];

	useEffect(() => {
		mapLanguages(state.qsiteSettings);
	},[]);

	return (
		<PageContainer flex customStyle={customStyle}>
			{ displayBreadcrumbs &&
				(
					<Breadcrumbs
						type={'navigation'}
						crumbs={breadcrumb}
						navigationConfig={breadcrumbsNavigationConfig}
						translatedKeys={{ ariaLabel: i18n.t('general.breadcrumbs_aria_label') }}
						customStyle={customStyle}
					/>
				)
			}
			{showPrices ? (
				<Title title={qsiteSettings.quotes.price_available.title} dataTestid='quote-title' customStyle={customStyle} />
			) : (
				<>
					<Title title={qsiteSettings.quotes.price_unavailable.title} dataTestid='quote-title' customStyle={customStyle} />
					<Description dataTestId='quote-description' customStyle={customStyle}>{qsiteSettings.quotes.price_unavailable.description}</Description>
				</>
			)}
			<Description customStyle={customStyle}>
			<Grid gridTemplateColumns={'repeat(2, 1fr)'} mobileGridTemplateColumns={'1fr'} gridGap='25px' gridWidth='600px'>
				<InfoCard title={i18n.t('quote.location')} customStyle={customStyle}>
					<p>{location.short_name}</p>
					<p>{location.address1}</p>
					<p>{location.city}, {location.state}</p>
					<p>{location.pri_phone}</p>
					<div className='change-information-container'>
						<FontAwesomeIcon icon={faMapMarkerAlt} />
						<a href="#" onClick={(e) => handleGetDirectionsClick(e)} >{i18n.t('quote.get_directions')}</a>
					</div>
				</InfoCard>
				<InfoCard customStyle={customStyle}>
					{hasParentDevice && (
						<h3>{i18n.t('quote.repair')} - {device?.name}</h3>
					)}
					{showPrices && <h3 className='quote-price' data-testid='quote-price'>${device?.advertisedPrice}</h3>}
					<p data-testid='quote-disclaimer'>{showPrices
						? <Description dangerouslySetInnerHTML={qsiteSettings.quotes.price_available.disclaimer} />
						: <Description dangerouslySetInnerHTML={qsiteSettings.quotes.price_unavailable.disclaimer} />
					}</p>
				</InfoCard>
				{device.name ?
					<InfoCard title={i18n.t('quote.device')} customStyle={customStyle}>
						<p>{device?.parent?.name_friendly || device?.parent?.name || device.name }</p>
					</InfoCard>
					:
					<InfoCard title={i18n.t('quote.problem')} customStyle={customStyle}>
						<p>{device.problem_description}</p>
					</InfoCard>
				}
			</Grid>
			<div className='quote-button'>
				{qsiteSettings.appointments.active === '1' ? (
					<Button
						onClick={() => {
							dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT, method: NAVIGATION_ACTION.PUSH }));
						}}
						label={i18n.t('quote.schedule_appointment')}
						dataTestId='quote-button'
						customStyle={customStyle}
					/>
				) : (
					<Button
						onClick={() => {
							dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.LOCATION, method: NAVIGATION_ACTION.PUSH }));
						}}
						label={i18n.t('quote.get_another_quote')}
						dataTestId='quote-button'
						customStyle={customStyle}
					/>
				)}
			</div>
			</Description>
		</PageContainer>
	);
}
