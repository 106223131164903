import { Modal } from '@qsite/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CancelSession({ callback }) {
	const i18n = useTranslation();

	return (
		<Modal
			root
			callback={callback}
			translatedKeys={{
				title: i18n.t('cancelSession.title'),
				yesButtonLabel: i18n.t('cancelSession.yes'),
				noButtonLabel: i18n.t('cancelSession.no'),
				startOfDialog: i18n.t('general.start_of_dialog'),
				endOfDialog: i18n.t('general.end_of_dialog')
			}}
		/>
	)
}
