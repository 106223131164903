import { Button, Description, NAVIGATION_ACTION, PageContainer, Title } from '@qsite/components';
import { appSettingsActions, useReset, useStore } from '@qsite/services';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../utils/routesConfig';
import '../Pages.scss';

export default function Rejected() {
	const [state, dispatch] = useStore();
	const i18n = useTranslation();
	const { resetMediatorAndDevice } = useReset();
	const { theme: customStyle } = state.qsiteSettings;

	const clearAndRedirect = () => {
		resetMediatorAndDevice();
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.LOCATION , method: NAVIGATION_ACTION.PUSH }));
	}

	return (<>
		<PageContainer flex>
			<Title title={i18n.t('rejected.unable_to_accept')} customStyle={customStyle} />
			<Description>{i18n.t('rejected.thanks_for_completing')}</Description>
			<Button
				label={i18n.t('general.sell_another_device')}
				dataTestId='sell-another-device-button'
				onClick={clearAndRedirect}
				customStyle={customStyle}
			/>
		</PageContainer>
	</>)
}