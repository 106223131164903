import axios from "axios";

const instance = axios.create({
	baseURL: getBaseUrl(),
	timeout: 5000,
	headers: { 'X-REPAIRQ-APP': 'qsite' },
	withCredentials: true,
});

function getBaseUrl() {
	if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
		return process.env.REACT_APP_API_URL;
	} else {
		return `${window.location.origin}/api`;
	}
}

export default instance;