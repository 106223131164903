
const CATALOG_STEP = {
	SCREEN_SIZE: 0,
	MAKE: 1,
	MODEL: 2
}

export const useValidation = () => {
	const isResponseValid = (response) => response.data?.data?.catalogItem?.length > 0

	const isOnMakeCatalogStep = (selectedOption, catalogStep) => catalogStep === CATALOG_STEP.MAKE && (selectedOption.categories || selectedOption.catalog_items)

	function isCategory(selectedOption) { return selectedOption.categories && selectedOption.categories.length > 0 }

	function isCatalogItem(selectedOption) { return selectedOption.catalog_items && selectedOption.catalog_items.length > 0 }

	function isDevice(selectedOption) { return selectedOption.hasOwnProperty('sku') }
	
	function isCompatibleService(selectedOption) { return !selectedOption.hasOwnProperty('sku') && !selectedOption.hasOwnProperty('categories') && !selectedOption.hasOwnProperty('catalog_items')}

	return { isResponseValid, isOnMakeCatalogStep, isCategory, isCatalogItem, isDevice, isCompatibleService }
}