"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setStep = function setStep(desiredStep) {
  return {
    type: _types["default"].SET_STEP,
    payload: desiredStep
  };
};

var nextStep = function nextStep() {
  return {
    type: _types["default"].NEXT_STEP
  };
};

var resetStep = function resetStep() {
  return {
    type: _types["default"].RESET_STEP
  };
};

var _default = {
  setStep: setStep,
  nextStep: nextStep,
  resetStep: resetStep
};
exports["default"] = _default;