"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_STEP: 'SET_STEP',
  NEXT_STEP: 'NEXT_STEP',
  RESET_STEP: 'RESET_STEP'
};
exports["default"] = _default;