"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var appointmentState = {
  day: '',
  time: '',
  rawTime: '',
  appointment_id: '',
  location: {},
  timeWindow: {}
};
var _default = appointmentState;
exports["default"] = _default;