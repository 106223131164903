import { Description, PageContainer, Title } from '@qsite/components';
import { useStore } from '@qsite/services';
import { useReset } from '@qsite/services/lib/hooks';
import React, { useContext, useEffect, useState } from 'react';
import { deleteSession } from '../../services/api/appSettings';
import '../Pages.scss';
import './Location.scss';
import LocationSearchContainer from './LocationSearchContainer';
import { LangContext } from '../../App';

export default function Location() {
	const [state, ] = useStore();
	const SETTINGS = state.qsiteSettings;
	const { resetQsiteInitialState } = useReset();
	const [title, setTitle] = useState(SETTINGS.locations.title || null);
	const [description, setDescription] = useState(SETTINGS.locations.description || null);
	const { isLangChanged, setIsLangChanged, mapLanguages,  } = useContext(LangContext);

	useEffect(() => {
		mapLanguages(SETTINGS);
	}, [SETTINGS]);

	useEffect(() => {
		resetQsiteInitialState();
		deleteSession();
		if (isLangChanged) {
			setIsLangChanged(false);
		}
	}, [resetQsiteInitialState]);

	useEffect(() => {
		setTitle(SETTINGS.locations.title);
		setDescription(SETTINGS.locations.description);
	});

	return (
		<PageContainer flex customStyle={SETTINGS.theme}>
			<Title title={title} customStyle={SETTINGS.theme} />
			<Description customStyle={SETTINGS.theme}>{description}</Description>
			<Description customStyle={SETTINGS.theme}>
				<LocationSearchContainer setTitle={setTitle} setDescription={setDescription} />
			</Description>
		</PageContainer>
	);
}
