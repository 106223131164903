import _ from 'lodash';
import { Description, NAVIGATION_ACTION, PageContainer, Title, Breadcrumbs, Modal, Button, Input, Grid, AlertBox } from '@qsite/components';
import { appSettingsActions, customerInformationActions, ticketActions, useStore } from '@qsite/services';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createTicket } from '../../services/api/ticket';
import { APP_ROUTES, breadcrumbsNavigationConfig } from '../../utils/routesConfig';
import '../Pages.scss';
import './ContactInfo.scss';
import contactInfoConfig from './ContactInfoConfig';
import { validationSchemaDefault, validationSchemaPhoneNumberLength } from './validationSchema';
import { LangContext } from '../../App';
import { Field, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFaIcon, onPhoneChange } from './ContactFormUtils/utils';
import { ErrorMessageCustomed } from '@qsite/components';

function focusOnFirstInvalidField(errors, touched) {
	if (!_.isEmpty(errors)) {
		const firstInvalidField = Object.keys(errors)[0];
		document.getElementsByName(firstInvalidField)[0].focus();
	} else if (!_.isEmpty(touched)) {
		const firstTouchedField = Object.keys(touched)[0];
		document.getElementsByName(firstTouchedField)[0].focus();
	}
}

export default function ContactInfo() {
	const i18n = useTranslation();
	const [state, dispatch] = useStore();
	const qsiteFieldSettings = state.qsiteSettings.quotes.contact;
	const [validationSchema, setValidationSchema] = useState(validationSchemaDefault);
	const [contactInfoConfigResult, setContactInfoConfigResult] = useState(contactInfoConfig(qsiteFieldSettings, handleClickShowModal));
	const displayBreadcrumbs = state.qsiteSettings.theme?.breadcrumbs ?? '0';
	const isCaslEnabled = qsiteFieldSettings.enable_casl;
	const removeFields = [
		'receiveNotifications',
		'receiveMarketing',
	];
	const { appLang, mapLanguages } = useContext(LangContext);
	const RQTermsOfUse = state.qsiteSettings?.terms;
	const legalFooterLength = qsiteFieldSettings?.legal_footer?.length;
	const legalFooterMaxLength = 250;

	// CASL modal state
	const [showModal, setShowModal] = useState(false);
	const [showRQModal, setShowRQModal] = useState(false);
	const [formValues, setFormValues] = useState({});
	const [isReadMore, setIsReadMore] = useState(true);
	const [clickSubmitting, setClickSubmitting] = useState(false);

	const VALIDATION_RULES = state.qsiteSettings?.validation_rules;
	const customStyle = state.qsiteSettings?.theme;

	useEffect(() => {
		handleValidationRules();
	});

	useEffect(() => {
		mapLanguages(state.qsiteSettings);
	}, []);

	useEffect(() => {
		const validationToUse = qsiteFieldSettings?.validate_phone_length === false  ? validationSchemaPhoneNumberLength : validationSchemaDefault ?? validationSchemaDefault;
		setValidationSchema(validationToUse);
		setContactInfoConfigResult(contactInfoConfig(qsiteFieldSettings, handleClickShowModal));
	}, [qsiteFieldSettings]);

	const breadcrumb = 	[
		{ name: i18n.t('breadcrumbs.location'), pathToNavigate: APP_ROUTES.LOCATION },
		{ name: i18n.t('breadcrumbs.identification'), pathToNavigate: APP_ROUTES.DEVICE_ID },
		{ name: i18n.t('breadcrumbs.contact'), pathToNavigate: APP_ROUTES.CUSTOMER },
	]

	const initialValues = {
		firstName: state.customerInformation.first_name,
		lastName: state.customerInformation.last_name,
		email: state.customerInformation.email,
		phoneNumber: state.customerInformation.pri_phone,
		problemDescription: state.customerInformation.problem_description || '',
		receiveNotifications: state.customerInformation.receive_notifications,
		receiveMarketing: state.customerInformation.receive_marketing,
	};

	function onSubmit(values) {
		const {
			firstName,
			lastName,
			email,
			phoneNumber,
			problemDescription,
			receiveNotifications,
			receiveMarketing,
		} = values;

		const customer = {
			first_name: firstName,
			last_name: lastName,
			email: email,
			pri_phone: phoneNumber,
			receive_notifications: receiveNotifications === '1' ? 1 : 0,
			receive_marketing: receiveMarketing === '1' ? 1 : 0,
		}

		const ticket = ticketBuilder(Object.keys(state.device.parent).length > 0, customer, problemDescription);

		createTicket(ticket)
			.then((res) => {
				dispatch(ticketActions.setTicketInformation(res.data.data.ticket));
				dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.QUOTE, method: NAVIGATION_ACTION.PUSH }))
			})
			.catch((error) => {
				console.error(error);
			});

		const customerInformation = {
			...customer,
			problem_description: problemDescription,
		}

		dispatch(customerInformationActions.setCustomerInformation(customerInformation));
	}

	function ticketBuilder(hasRepairOption, customer, problemDescription) {
		let ticket = {
			type: 'quote',
			customer,
			location_id: state.location.id,
			notes: [
				{
					note: problemDescription,
				},
			],
			device_catalog_item_id: hasRepairOption ? state.device.parent.id : state.device.id,
			problem_description: problemDescription,
		}

		if (hasRepairOption) {
			ticket = {
				...ticket,
				ticketItemsAll: [{
					catalog_item_id: state.device.id,
				}]
			}
		}

		let urlQueryValues = localStorage.getItem('urlQueryValues');
		urlQueryValues = urlQueryValues ? JSON.parse(urlQueryValues) : null;
		if(urlQueryValues) {
			ticket = {
				...ticket,
				...urlQueryValues,
			}
		}

		return ticket
	}

	const handleClickShowModal = () => {
		setShowModal(true);
	}

	const renderTermsAndConditionsModal = () => {
		return (<Modal
				root
				callback={setShowModal}
				translatedKeys={{
					title: i18n.t('customerInfo.terms_and_conditions'),
					description: qsiteFieldSettings.terms,
					noButtonLabel: i18n.t('customerInfo.close'),
					startOfDialog: i18n.t('general.start_of_dialog'),
					endOfDialog: i18n.t('general.end_of_dialog')
				}}
				customStyle={customStyle}
			/>);
	}

	const handleValidationRules = () => {
		if (VALIDATION_RULES && VALIDATION_RULES.length) {
			const customerRules = VALIDATION_RULES.find((rule) => rule.model_name === 'customer');

			if (customerRules && customerRules.options) {
				const { options } = customerRules;
				const optionValues = Object.values(options ?? {});

				if (!optionValues?.includes('receive_service_email_notifications')) {
					delete validationSchema.fields.receiveNotifications;
				}

				if (!optionValues?.includes('receive_email_marketing')) {
					delete validationSchema.fields.receiveMarketing;
				}

				setValidationSchema(validationSchema);
			}
		}
	}

	const filterContactFields = () => {
		let fields = contactInfoConfigResult.formFields;

		// other divice name validation. Change placeholder for problemDescription
		const regex = /other/i;
		if (regex.test(state.device.name)) {
			fields = fields.map((field) => {
				if (field.id === 'problemDescription') {
					return {...field, placeholder: 'customerInfo.device_description'};
				}

				return field;
			});
		}


		if (!isCaslEnabled) {
			fields = contactInfoConfigResult.formFields.filter((field) => {
				if (!removeFields.includes(field.name)) {
					return field;
				}
			});
		}

		return fields;
	}

	const handleClickShowRQModal = (values) => {
		setShowRQModal(true);
		setFormValues(values);
	}

	const RQTermsOfUseCallback = (e) => {
		if (e) {
			onSubmit(formValues);
		}

		setShowRQModal(false);
	}

	const renderRQConditionsModal = () => {
		return (<Modal
				root
				callback={RQTermsOfUseCallback}
				translatedKeys={{
					title: i18n.t('customerInfo.terms_of_use'),
					description: RQTermsOfUse.content,
					yesButtonLabel: i18n.t('customerInfo.i_agree'),
					noButtonLabel: i18n.t('customerInfo.close'),
					startOfDialog: i18n.t('general.start_of_dialog'),
					endOfDialog: i18n.t('general.end_of_dialog')
				}}
				customStyle={customStyle}
			/>);
	}

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	return (
		<PageContainer flex customStyle={customStyle}>
			{ displayBreadcrumbs  &&
				(
					<Breadcrumbs
						type={'navigation'}
						crumbs={breadcrumb}
						navigationConfig={breadcrumbsNavigationConfig}
						translatedKeys={{ ariaLabel: i18n.t('general.breadcrumbs_aria_label') }}
						customStyle={customStyle}
					/>
				)
			}
			<Title title={state.qsiteSettings.quotes.contact.title} customStyle={customStyle} />
			<Description customStyle={customStyle}>{state.qsiteSettings.quotes.contact.description || null}</Description>
			<Description customStyle={customStyle}>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				validateOnChange={true}
				validateOnBlur={true}
			>
				{({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue, isValid }) => (
					<>
					{clickSubmitting && errors && Object.keys(errors)?.length ? (
						<Grid
							gridTemplateColumns='repeat(1, 1fr)'
							gridGap='10px'
							mobileGridTemplateColumns='1fr'
							gridWidth='50%'
						>
							<AlertBox type='error' role='something' customStyle={customStyle}>There was a problem with your submission. Please review the fields below.</AlertBox>
						</Grid>
					) : ''}
					<form
						className={'form-container'}
						data-testid='form'
						onSubmit={handleSubmit}
					>
						<Grid
							gridTemplateColumns='repeat(1, 1fr)'
							gridGap='10px'
							mobileGridTemplateColumns='1fr'
							gridWidth='50%'
						>
							{isSubmitting && !isValid && (
								focusOnFirstInvalidField(errors, touched),
								setClickSubmitting(true)
							)}
							<span className={`firstNameField`} key={'firstName'}>
								<Input
									id={'firstName'}
									name={'firstName'}
									type={'text'}
									icon={<FontAwesomeIcon icon={getFaIcon('faUserAlt')} />}
									dataTestId={'firstName'}
									placeholder={i18n.t('customerInfo.first_name')}
									value={values['firstName']}
									ariaInvalid={!!errors['firstName']}
									ariaDescribedBy={`firstName-error firstName-format`}
									ariaRequired={true}
									className={
										errors['firstName'] && touched['firstName'] ? 'invalid' : ''
									}
									onChange={handleChange}
									customStyle={customStyle}
									hideIcon={customStyle?.label?.disable || false}
									hideLabel={customStyle?.label?.disable || false}
								/>
								{touched['firstName'] && errors['firstName'] && (
									<ErrorMessageCustomed
										data-testid={`firstName-error`}
										name={'firstName'}
										message={errors['firstName']}
										customStyle={customStyle}
									/>
								)}
							</span>

							<span className={`lastNameField`} key={'lastName'}>
								<Input
									id={'lastName'}
									name={'lastName'}
									type={'text'}
									icon={<FontAwesomeIcon icon={getFaIcon('faUserAlt')} />}
									dataTestId={'lastName'}
									placeholder={i18n.t('customerInfo.last_name')}
									value={values['lastName']}
									ariaInvalid={!!errors['lastName']}
									ariaDescribedBy={`lastName-error lastName-format`}
									ariaRequired={true}
									className={
										errors['lastName'] && touched['lastName'] ? 'invalid' : ''
									}
									onChange={handleChange}
									customStyle={customStyle}
									hideIcon={customStyle?.label?.disable || false}
									hideLabel={customStyle?.label?.disable || false}
								/>
								{touched['lastName'] && errors['lastName'] && (
									<ErrorMessageCustomed
										data-testid={`lastName-error`}
										name={'lastName'}
										message={errors['lastName']}
										customStyle={customStyle}
									/>
								)}
							</span>

							<span className={`emailField`} key={'email'}>
								<Input
									id={'email'}
									name={'email'}
									type={'email'}
									icon={<FontAwesomeIcon icon={getFaIcon('faEnvelope')} />}
									dataTestId={'email'}
									placeholder={i18n.t('customerInfo.email')}
									value={values['email']}
									ariaInvalid={!!errors['email']}
									ariaDescribedBy={`email-error email-format`}
									ariaRequired={true}
									className={
										errors['email'] && touched['email'] ? 'invalid' : ''
									}
									onChange={handleChange}
									customStyle={customStyle}
									hideIcon={customStyle?.label?.disable || false}
									hideLabel={customStyle?.label?.disable || false}
								/>
								{touched['email'] && errors['email'] && (
									<ErrorMessageCustomed
										data-testid={`email-error`}
										name={'email'}
										message={errors['email']}
										customStyle={customStyle}
									/>
								)}
							</span>

							<span className={`phoneNumberField`} key={'phoneNumber'}>
								<Input
									id={'phoneNumber'}
									name={'phoneNumber'}
									type={'tel'}
									icon={<FontAwesomeIcon icon={getFaIcon('faMobile')} />}
									dataTestId={'phoneNumber'}
									placeholder={i18n.t('customerInfo.phone_number')}
									value={values['phoneNumber']}
									ariaInvalid={!!errors['phoneNumber']}
									ariaDescribedBy={`phoneNumber-error phoneNumber-format`}
									ariaRequired={true}
									maxLength={40}
									className={
										errors['phoneNumber'] && touched['phoneNumber'] ? 'invalid' : ''
									}
									onChange={(e) => {
										const phoneFormat = onPhoneChange(e, true);
										setFieldValue('phoneNumber', phoneFormat);
									}}
									customStyle={customStyle}
									hideIcon={customStyle?.label?.disable || false}
									hideLabel={customStyle?.label?.disable || false}
								/>
								{touched['phoneNumber'] && errors['phoneNumber'] && (
									<ErrorMessageCustomed
										data-testid={`phoneNumber-error`}
										name={'phoneNumber'}
										message={errors['phoneNumber']}
										customStyle={customStyle}
									/>
								)}
							</span>
						</Grid>

						<Grid
							gridTemplateColumns='repeat(1, 1fr)'
							gridGap='10px'
							mobileGridTemplateColumns='1fr'
							gridWidth='50%'
						>
							<span className={`problemDescriptionField`} key={'problemDescription'}>
								<Input
									id={'problemDescription'}
									name={'problemDescription'}
									type={'textarea'}
									icon={<FontAwesomeIcon icon={getFaIcon('faComment')} />}
									dataTestId={'problemDescription'}
									placeholder={i18n.t('customerInfo.problem_description')}
									value={values['problemDescription']}
									ariaRequired={true}
									className={
										errors['problemDescription'] && touched['problemDescription'] ? 'invalid' : ''
									}
									onChange={handleChange}
									customStyle={customStyle}
									hideIcon={customStyle?.label?.disable || false}
									hideLabel={customStyle?.label?.disable || false}
								/>
								{touched['problemDescription'] && errors['problemDescription'] && (
									<ErrorMessageCustomed
										data-testid={`problemDescription-error`}
										name={'problemDescription'}
										message={errors['problemDescription']}
										customStyle={customStyle}
									/>
								)}
							</span>
						</Grid>

						<Grid
							gridTemplateColumns='repeat(2, 1fr)'
							gridGap='10px'
							mobileGridTemplateColumns='1fr'
							gridWidth='50%'
						>
							<div role="group" className={`receiveNotificationsField`} key={'receiveNotifications'} aria-labelledby={'receiveNotificationsLabel'}>
								<span id={'receiveNotificationsLabel'}>{i18n.t('customerInfo.receive_notifications')}</span>
								<div><label>
									<Field
										type="radio"
										name={'receiveNotifications'}
										value={'1'}
										className={errors['receiveNotifications'] && touched['receiveNotifications'] ? 'invalid' : ''}
									/>
									{i18n.t('general.yes')}
								</label></div>
								<div><label>
									<Field
										type="radio"
										name={'receiveNotifications'}
										value={'0'}
										className={errors['receiveNotifications'] && touched['receiveNotifications'] ? 'invalid' : ''}
									/>
									{i18n.t('general.no')}
								</label></div>
								{touched['receiveNotifications'] && errors['receiveNotifications'] && (
									<ErrorMessageCustomed
										data-testid={`receiveNotifications-error`}
										name={'receiveNotifications'}
										message={errors['receiveNotifications']}
										customStyle={customStyle}
									/>
								)}
							</div>

							<div role="group" className={`receiveNotificationsField`} key={'receiveMarketing'} aria-labelledby={'receiveNotificationsLabel'}>
								<span id={'receiveNotificationsLabel'}>{i18n.t('customerInfo.receive_marketing')}</span>
								<div><label>
									<Field
										type="radio"
										name={'receiveMarketing'}
										value={'1'}
										className={errors['receiveMarketing'] && touched['receiveMarketing'] ? 'invalid' : ''}
									/>
									{i18n.t('general.yes')}
								</label></div>
								<div><label>
									<Field
										type="radio"
										name={'receiveMarketing'}
										value={'0'}
										className={errors['receiveMarketing'] && touched['receiveMarketing'] ? 'invalid' : ''}
									/>
									{i18n.t('general.no')}
								</label></div>
								{touched['receiveMarketing'] && errors['receiveMarketing'] && (
									<ErrorMessageCustomed
										data-testid={`receiveMarketing-error`}
										name={'receiveMarketing'}
										message={errors['receiveMarketing']}
										customStyle={customStyle}
									/>
								)}
							</div>

						</Grid>
						<Button
							type='submit'
							label={i18n.t('customerInfo.get_a_quote')}
							dataTestId='form-submit-button'
							disabled={isSubmitting}
							customStyle={customStyle}
						/>
					</form>
					</>
				)}
			</Formik>
			</Description>
			{
				qsiteFieldSettings?.legal_footer && (
					<Description
						dangerouslySetInnerHTML={qsiteFieldSettings?.legal_footer}
						customStyle={{...customStyle, margin: '10px auto 0 auto', isReadMore}}
					>
						{
							(legalFooterLength && legalFooterLength > legalFooterMaxLength) && (
								<Button
									type='button'
									label={isReadMore ? i18n.t('general.read_more') : i18n.t('general.read_less')}
									onClick={toggleReadMore}
									customStyle={{...customStyle, margin: '0px', padding: '5px', minWidth: 'auto'}}
								/>
							)
						}
					</Description>
				)
			}
		</PageContainer>
	);
}
