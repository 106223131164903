"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var locationState = {
  id: null,
  legal_name: null,
  short_name: null,
  address1: null,
  city: null,
  state: null,
  zip: null,
  pri_phone: null
};
var _default = locationState;
exports["default"] = _default;