import api from '.';

export function getLocation(config) {
	return api.get('/location', config);
}

/**
 * Retrieve a location by Id
 * @param id
 * @returns Object
 */
export function getLocationById(id) {
	return api.get(`/location/${id}`);
}
