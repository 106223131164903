import { CustomRoute } from '@qsite/components';
import React, { ComponentClass, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useStore } from '@qsite/services';
import routesConfig, { APP_ROUTES } from '../utils/routesConfig';

interface CustomRouteQSITEProps {
	path: string;
	component: ComponentClass;
}

export const CustomRouteQSITE: FunctionComponent<CustomRouteQSITEProps> = ({ path, component }) => {
	const i18n = useTranslation();
	const [state] = useStore();
	const appTitle = state?.qsiteSettings?.customName ?? i18n.t('pageTitles.appTitle');

	const pageTitles: { [key: string]: string } = {
		[APP_ROUTES.LOCATION]: i18n.t('pageTitles.location'),
		[APP_ROUTES.DEVICE_ID]: i18n.t('pageTitles.deviceIdentification'),
		[APP_ROUTES.CUSTOMER]: i18n.t('pageTitles.contactInfo'),
		[APP_ROUTES.QUOTE]: i18n.t('pageTitles.quote'),
		[APP_ROUTES.APPOINTMENT]: i18n.t('pageTitles.appointment'),
		[APP_ROUTES.APPOINTMENT_CONFIRMATION]: i18n.t('pageTitles.appointmentConfirmation'),
		[APP_ROUTES.APPOINTMENT_CONFIRMED]: i18n.t('pageTitles.appointmentConfirmed'),
		[APP_ROUTES.APPOINTMENT_CANCELLATION]: i18n.t('pageTitles.appointmentCancellation'),
		[APP_ROUTES.APPOINTMENT_CANCELLED]: i18n.t('pageTitles.appointmentCancelled'),
		[APP_ROUTES.TICKET]: i18n.t('pageTitles.ticket'),
		[APP_ROUTES.TICKET_LOOKUP]: i18n.t('pageTitles.ticketLookup'),
		[APP_ROUTES.REJECTED]: i18n.t('pageTitles.rejected')
	}
	
	return <CustomRoute title={`${pageTitles[path]} | ${appTitle}`} path={path} component={component} routesConfig={routesConfig} />;
}
