"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_CUSTOMER_INFORMATION: 'SET_CUSTOMER_INFORMATION',
  RESET_CUSTOMER_INFORMATION: 'RESET_CUSTOMER_INFORMATION',
  RESET_CUSTOMER_PERSONAL_INFORMATION: 'RESET_CUSTOMER_PERSONAL_INFORMATION'
};
exports["default"] = _default;