export default {
  "general": {
    "continue": "Continuer",
    "sell_another_device": "Vendre un autre appareil",
    "yes": "Oui",
    "no": "Non",
    "select": "Sélectionner",
    "loading": "Chargement...",
    "loading_page": "Chargement de la page",
    "start_of_dialog": "Début du dialogue",
    "end_of_dialog": "Fin du dialogue",
    "breadcrumbs_aria_label": "fil d'Ariane",
    "read_more": "En savoir plus",
    "read_less": "Apprenez moins",
  },
  "pageTitles": {
    "appTitle": "Qsite",
    "location": "Emplacement",
    "deviceIdentification": "Identification de l'appareil",
    "contactInfo": "Informations de contact",
    "quote": "Devis",
    "appointment": "Rendez-vous",
    "appointmentConfirmation": "Confirmation du rendez-vous",
    "appointmentConfirmed": "Rendez-vous confirmé",
    "appointmentCancellation": "Annulation du rendez-vous",
    "appointmentCancelled": "Rendez-vous annulé",
    "ticket": "Ticket",
    "ticketLookup": "Recherche de ticket",
    "rejected": "Rejeté"
  },
  "validations": {
    "required": "Requis",
    "only_letters": "Seulement des lettres autorisées",
    "only_numbers": "Seulement des chiffres autorisés",
    "must_be_length": "Doit contenir {{length}} caractères",
    "min_length": "Un minimum de {{length}} caractères est nécessaire",
    "invalid_email": "Email invalide",
    "invalid_only_whitespace": "Seulement l'espace n'est pas autorisé"
  },
  "location": {
    "use_my_location": "Utiliser ma position actuelle",
    "geo_not_available": "Géolocalisation non disponible dans votre navigateur. Veuillez ajouter votre adresse manuellement.",
    "denied_access": "Vous avez refusé l'accès à votre position",
    "no_locations_found": "Aucun emplacement trouvé dans un rayon de {{distance}} {{distance_unit}} de votre position.",
    "please_update": "Veuillez mettre à jour votre position ou <1>cliquez ici</1> pour rafraîchir",
    "search_placeholder": "Adresse, Ville, ou Code Postal",
    "appt_time": "Prochain horaire de rdv disponible",
    "select_distance": "{{distance}} miles"
  },
  "customerInfo": {
    "get_a_quote": "Soumettre une demande",
    "close": "Fermer",
    "terms_of_use": "Conditions d'utilisation",
    "terms_and_conditions": "Conditions générales",
    "i_agree": "J'accepte",
    "first_name": "Prénom",
    "last_name": "Nom",
    "email": "Courriel",
    "phone_number": "Numéro de téléphone",
    "problem_description": "Veuillez entrer le modèle de votre appareil et une description du problème",
    "device_description": "Veuillez entrer le modèle de votre appareil et une description du problème",
    "receive_notifications": 'J\'accepte de recevoir des avis électroniques à propos de ce service.¹',
    "receive_marketing": 'J\'accepte de recevoir des messages électroniques de Mobile Klinik et de TELUS au sujet de promotions, d\'offres spéciales et de nouvelles concernant des événements ou des services.²*'
  },
  "rejected": {
    "unable_to_accept": "Impossible d'accepter l'appareil",
    "thanks_for_completing": "Merci d'avoir complété l'évaluation de votre appareil. Cependant, basé sur l'évaluation que vous avez complétée, nous ne pouvons pas accepter votre appareil."
  },
  "deviceIdentification": {
    "identify_device": "Identifier l'appareil",
    "identify_device_desc": "Commençons ! Pour obtenir un devis précis pour votre appareil, commencez par nous dire ce que vous avez.",
    "other": "Autre"
  },
  "cancelSession": {
    "title": "Êtes-vous sûr de vouloir annuler cette session ?",
    "no": "Non, Continuer",
    "yes": "Oui, Fermer"
  },
  "errorScreen": {
    "try_again_message": "Ou <1>cliquez ici</1> pour réessayer",
    "error_code": "Code d'erreur :",
    "error_message": "Message d'erreur :",
    "error_ref": "Numéro de référence d'erreur :"
  },
  "appointment": {
    "title": "Sélectionnez un jour et une heure de rendez-vous",
    "description": "Un rendez-vous est recommandé, mais les visiteurs sans rendez-vous sont toujours les bienvenus !",
    "no_available_appt": "Désolé, aucun horaire de rendez-vous disponible pour la date choisie"
  },
  "maintenance": {
    "title": "Nous sommes actuellement en maintenance.",
    "description": "Merci de nous visiter. Nous effectuons actuellement une maintenance programmée et serons de retour bientôt"
  },
  "appointmentConfirmation": {
    "title": "Veuillez confirmer les détails de votre rendez-vous",
    "day_time": "Jour/Heure du rendez-vous",
    "contact_information": "Informations de contact",
    "location": "Emplacement",
    "device": "Appareil",
    "get_directions": "Obtenir des directions",
    "confirm_appointment": "Confirmer le rendez-vous",
    "change_day_time": "Changer le Jour/Heure"
  },
  "appointmentConfirmed": {
    "title": "Merci ! Votre rendez-vous est programmé",
    "get_another_quote_button_label": "Obtenir un autre devis",
    "cancel_appointment": "Annuler le rendez-vous",
    "view_summary_button_label": "Voir le résumé"
  },
  "appointmentCancellation": {
    "title": "Êtes-vous sûr de vouloir annuler votre rendez-vous ?",
    "keep_appointment": "Garder le Rendez-vous",
    "cancel_appointment": "Oui, Annuler le Rendez-vous"
  },
  "appointmentCancelled": {
    "title": "Votre rendez-vous a été annulé",
    "get_another_appointment_button_label": "Obtenir un autre rendez-vous",
    "get_another_quote_button_label": "Obtenir un autre devis",
    "view_summary_button_label": "Voir le résumé"
  },
  "ticket": {
    "title": "Résumé du service",
    "description": "Voir les détails de votre service ci-dessous",
    "problem_description": "Description du problème",
    "schedule_appointment": "Planifier un rendez-vous",
    "cancel_appointment": "Annuler le rendez-vous",
    "hello": "Bonjour",
    "ticket_number": "Ticket n°",
    "notes": "Notes",
    "noNotes": "Il n'y a pas de notes pour le moment."
  },
  "ticketLookup": {
    "title": "Recherche de ticket",
    "description": "Entrez votre numéro de ticket ou d'autorisation ci-dessous pour recevoir un lien sécurisé",
    "input_placeholder": "Numéro d'autorisation",
    "button_label": "Envoyez-moi un lien sécurisé",
    "valid_ticketID": "Lien sécurisé envoyé",
    "invalid_ticketID": "Ticket non trouvé"
  },
  "quote": {
    "get_directions": "Obtenir des directions",
    "repair": "Réparation",
    "device": "Appareil",
    "problem": "Problème",
    "location": "Emplacement",
    "schedule_appointment": "Planifier un rendez-vous",
    "get_another_quote": "Obtenir un autre devis"
  },
  "breadcrumbs": {
    "aria_label": "fil d'Ariane",
    "identification": "Identification",
    "appointment": "Rendez-vous",
    "contact": "Contact",
    "quote": "Devis",
    "location": "Emplacement",
    "confirmation": "Confirmation",
    "cancellation": "Annulation"
  }
}
