import { StoreProvider } from '@qsite/services';
import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import './App.css';
import './i18n/';
import Routes from './Routes';
import { mirageServer } from '@qsite/services';
import { customMirageRoutes } from './services/mocks/customMirageRoutes'
import { Dropdown, Navbar } from '@qsite/components';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import UserConfirmation from './pages/CancelSession/UserConfirmation';

if (process.env.NODE_ENV === 'development') mirageServer({ customMirageRoutes });

/* eslint import/no-webpack-loader-syntax: off */
// Extract our Sass variables into a JS object
export const theme = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!./pages/variables.scss');

export const LangContext = createContext();

export const GTMContext = createContext();

const langDivSx = {
	display:'flex',
	flexDirection:'row-reverse',
	alignContent: 'flex-end',
	alignItems:'flex-end',
	margin: '20px',
};

function App() {
	// translation hook
	const { i18n } = useTranslation();
	// language state
	const [appLang, setAppLang] = useState('en_US');
	const [isLangChanged, setIsLangChanged] = useState(false);
	const [languagesList, setLanguagesList] = useState([]);
	const [customStyle, setcustomStyle] = useState({});
	const [customFavicon, setCustomFavicon] = useState('');
	const [customBackground, setCustomBackground] = useState('white');

	useEffect(() => {
		if (!customFavicon || customFavicon !== '') {
			updateFavicon(customFavicon);
		}

		if (!customBackground || customBackground !== '') {
			changeBackgroundColor(customBackground);
		}
	}, [customFavicon, customBackground]);

	const onChangeAppLang = (event) => {
		let { value } = event?.target || event;

		if (value === 'fr') {
			value = 'fr_CA';
		}

		setAppLang(value);
		setIsLangChanged(true);
		// change app language
		i18n.changeLanguage(value);
	}

	const mapLanguages = (qsiteSettings) => {
		const langKeys = Object.keys(qsiteSettings?.languages || {});
		const languages = langKeys && langKeys.length ?
						langKeys.map((key) => ({label: qsiteSettings.languages[key], value: key}))
						: [{label: 'English (US)', value: 'en_US'}];
		setLanguagesList(languages);
		setcustomStyle(qsiteSettings?.theme);
		setCustomFavicon(qsiteSettings?.theme?.brand?.favicon);
		setCustomBackground(qsiteSettings?.theme?.background?.main);
	};

	const gtmInitialize = (gtmId) => {
		TagManager.initialize({
			gtmId: gtmId,
			dataLayer: {
				page: 'App',
			},
			dataLayerName: 'PageDataLayer'
		});
	}
	const basename = process.env.NODE_ENV === 'development' ? '/' : '/qsite';

	const updateFavicon = (faviconURL) => {
		const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = faviconURL;
		document.getElementsByTagName('head')[0].appendChild(link);
	};

	const changeBackgroundColor = (color) => {
		const body = document.body;
		body.style.backgroundColor = color;
	};

	return (
		<LangContext.Provider value={{ appLang, onChangeAppLang, isLangChanged, setIsLangChanged, mapLanguages }}>
			<div className="App">
				<ThemeProvider theme={theme}>
					{
						customStyle?.enableNavbar ? (
							<Navbar customStyle={customStyle}>
								<div className='container'>
									<div className='brand'>
										<img
											alt='brand-logo'
											src={customStyle?.brand?.logo}
											className='brand-logo'
										/>
									</div>
									<div className='right-option'>
										<Dropdown
											title={appLang ? languagesList.find((option) => option.value == appLang)?.label : 'Language'}
											options={languagesList}
											customStyle={customStyle}
											onSelect={onChangeAppLang}
										/>
									</div>
								</div>
							</Navbar>
						) : (
							<div style={langDivSx}>
								<Dropdown
									title={appLang ? languagesList.find((option) => option.value == appLang)?.label : 'Language'}
									options={languagesList}
									customStyle={customStyle}
									onSelect={onChangeAppLang}
								/>
							</div>
						)
					}
					<GTMContext.Provider value={{gtmInitialize}}>
						<StoreProvider>
							<BrowserRouter basename={basename} getUserConfirmation={(message, callback) => UserConfirmation(message, callback)}>
								<Routes />
							</BrowserRouter>
						</StoreProvider>
					</GTMContext.Provider>
				</ThemeProvider>
			</div>
		</LangContext.Provider>
	
	);
}

export default App;
