import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@qsite/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './LocationList.scss';

export default function LocationItem(props) {
	const i18n = useTranslation();
	const { address1, address2, distance, updateLoc, next_avail_appt, city, state, color, displayName, customStyle} = props;
	const name = props[displayName] || props.short_name;

	let next_appointment_element = '';
	let time = new Date(next_avail_appt).toTimeString().split(' ')[0];

	if (time !== 'Invalid') {
		time = time.slice(0, 5);

		let hour = time.split(':')[0];

		const minutes = time.split(':')[1];

		let tod = 'AM';
		if (Number(hour) >= 12) {
			tod = 'PM';
		}

		if (Number(hour) > 12) {
			hour %= 12;
			time = `${hour}:${minutes}`;
		}

		if (time[0] === '0') {
			time = time.slice(1);
		}

		next_appointment_element = <p style={{ color }}>{i18n.t('location.appt_time')}: {time} {tod}</p>;
	}

	return (
		<div className='container'>
			<div className='icon'>
				<FontAwesomeIcon icon={faMapMarkerAlt} style={{ color, fontSize: '2rem' }} />
			</div>
			<div>
				<h3 className='location-title'>{name}</h3>
				<p className='address'>
					{address1}
					<br />
					{address2}
					<br />
					{`${city}, ${state}`}
				</p>
				{next_appointment_element}
			</div>
			<div className='select'>
				<Button
					onClick={() => updateLoc(props)}
					buttonWidth='auto'
					label={i18n.t('general.select')}
					customStyle={customStyle}
				/>
				<p>
					{i18n.t('location.select_distance', { distance })}
				</p>
			</div>
		</div>
	);
}
