"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setProgress = function setProgress(progress) {
  return {
    type: _types["default"].SET_PROGRESS,
    payload: progress
  };
};

var setError = function setError(error) {
  return {
    type: _types["default"].SET_ERROR,
    payload: error
  };
};

var setIsMaintenance = function setIsMaintenance(status) {
  return {
    type: _types["default"].SET_IS_MAINTENANCE,
    payload: status
  };
};

var setApplicationModal = function setApplicationModal(status) {
  return {
    type: _types["default"].SET_APPLICATION_MODAL,
    payload: status
  };
};

var setInitialLoad = function setInitialLoad(status) {
  return {
    type: _types["default"].SET_INITIAL_LOAD,
    payload: status
  };
};

var _default = {
  setProgress: setProgress,
  setError: setError,
  setIsMaintenance: setIsMaintenance,
  setApplicationModal: setApplicationModal,
  setInitialLoad: setInitialLoad
};
exports["default"] = _default;