"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setCustomerInformation = function setCustomerInformation(customer) {
  return {
    type: _types["default"].SET_CUSTOMER_INFORMATION,
    payload: customer
  };
};

var resetCustomerInformation = function resetCustomerInformation() {
  return {
    type: _types["default"].RESET_CUSTOMER_INFORMATION
  };
};

var resetCustomerPersonalInformation = function resetCustomerPersonalInformation() {
  return {
    type: _types["default"].RESET_CUSTOMER_PERSONAL_INFORMATION
  };
};

var _default = {
  setCustomerInformation: setCustomerInformation,
  resetCustomerInformation: resetCustomerInformation,
  resetCustomerPersonalInformation: resetCustomerPersonalInformation
};
exports["default"] = _default;