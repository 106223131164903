import { Description, NAVIGATION_ACTION, PageContainer, Title, TimeList, Breadcrumbs } from '@qsite/components';
import { appointmentActions, appSettingsActions, locationActions, useStore } from '@qsite/services';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Calendar from '../../components/Calendar/Calendar';
import { getTimes } from '../../services/api/appointment';
import { APP_ROUTES, breadcrumbsNavigationConfig } from '../../utils/routesConfig';
import './Appointment.scss';
import { LangContext } from '../../App';

const APPOINTMENT_TYPE = {
	'TIME_LIST': 0,
	'TIME_WINDOW': 1
}

export const getAppointmentTypeFromStore = (type) => {
	switch (type) {
		case 'time':
			return APPOINTMENT_TYPE.TIME_LIST;
		case 'window':
			return APPOINTMENT_TYPE.TIME_WINDOW;
		default:
			return null;
	}
}

export default function Appointment() {
	const i18n = useTranslation();
	const [state, dispatch] = useStore();
	const [timeList, setTimeList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [noTimes, setNoTimes] = useState(false);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [appointmentsType] = useState(getAppointmentTypeFromStore(state.qsiteSettings?.appointments?.block));
	const displayBreadcrumbs = Boolean(state.qsiteSettings.theme?.breadcrumbs ?? false);
	const { mapLanguages } = useContext(LangContext);
	const appointmentSettings = state.qsiteSettings?.appointments;
	const { theme: customStyle } = state.qsiteSettings;

	useEffect(() => {
		mapLanguages(state.qsiteSettings);
	}, []);

	const breadcrumb = 	[
		{ name: i18n.t('breadcrumbs.location'), pathToNavigate: APP_ROUTES.LOCATION },
		{ name: i18n.t('breadcrumbs.identification'), pathToNavigate: APP_ROUTES.DEVICE_ID },
		{ name: i18n.t('breadcrumbs.contact'), pathToNavigate: APP_ROUTES.CUSTOMER },
		{ name: i18n.t('breadcrumbs.quote'), pathToNavigate: APP_ROUTES.QUOTE },
		{ name: i18n.t('breadcrumbs.appointment'), pathToNavigate: APP_ROUTES.APPOINTMENT },
	]

	useEffect(() => {
		function loadAppointmentTimes(locationID, day) {
			setIsLoading(true);
			return getTimes(locationID, day)
				.then(res => {
					const { location } = res.data.data;
					location.available_appointment_times.length === 0 ? setNoTimes(true) : setNoTimes(false);
					setTimeList(location.available_appointment_times);
					dispatch(locationActions.setLocation(location))
					setIsLoading(false);
				})
				.catch(err => {
					dispatch(appSettingsActions.setError({ showErrorScreen: true, errorResponse: err.response })); // Sends Error to application store to trigger Error Page
					setIsLoading(false);
				});
		};

		if (appointmentsType === APPOINTMENT_TYPE.TIME_LIST) {
			loadAppointmentTimes(state.location.id, currentDate.toDateString())
		}
		else if (appointmentsType === APPOINTMENT_TYPE.TIME_WINDOW) {
			setNoTimes(true)
			console.log("Appointment by time range is not available for Qsite.")
		}

	}, [currentDate, dispatch, state.location.id]);


	function handleChooseTime(t, timeString) {
		dispatch(appointmentActions.setAppointment({ time: timeString, rawTime: t, ticket_id: state.ticket.id, day: currentDate.toDateString() }));
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT_CONFIRMATION, method: NAVIGATION_ACTION.PUSH }));
	}

	return (
		<PageContainer flex customStyle={customStyle}>
			{ displayBreadcrumbs &&
				(
					<Breadcrumbs
						type={'navigation'}
						crumbs={breadcrumb}
						navigationConfig={breadcrumbsNavigationConfig}
						translatedKeys={{ ariaLabel: i18n.t('general.breadcrumbs_aria_label') }}
						customStyle={customStyle}
					/>
				)
			}
			<Title id='title' title={appointmentSettings?.time?.title} customStyle={customStyle} />
			<Description customStyle={customStyle}>{appointmentSettings?.time?.description}</Description>
			<Description customStyle={customStyle}>
			<div className='appointment-info'>
				<Calendar currentDate={currentDate} setCurrentDate={setCurrentDate} />
				<div className='location-info'>
					<p>{state.location.short_name}</p>
					<p>{state.location.address1}</p>
				</div>
			</div>
			</Description>
			<Description customStyle={customStyle}>
			{isLoading ? <h4>Loading...</h4>
				: noTimes ? <h4>Sorry, no available appointment times for the chosen date</h4>
					: <Description customStyle={customStyle}>
						<TimeList translatedKeyNoAvailableAppt={i18n.t('appointment.no_available_appt')} ariaLabelledBy='title' timeList={timeList} handleClick={handleChooseTime} />
					</Description>}
			</Description>
		</PageContainer>
	)
};
