"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var useFirstRender = function useFirstRender() {
  var firstRender = (0, _react.useRef)(true);
  (0, _react.useEffect)(function () {
    firstRender.current = false;
  }, []);
  return firstRender.current;
};

var _default = useFirstRender;
exports["default"] = _default;