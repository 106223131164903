export default {
	general: {
		continue: 'Continuar',
		sell_another_device: 'Vender Outro Dispositivo',
		yes: 'Sim',
		no: 'Não',
		select: 'Selecione',
		loading: 'Carregando...',
		loading_page: 'Carregando a página',
		start_of_dialog: 'Início do Diálogo',
		end_of_dialog: 'Fim do Diálogo',
		breadcrumbs_aria_label: 'navegação por categorias'
	},
	pageTitles: {
		appTitle: 'Qsite',
		location: 'Localização',
		deviceIdentification: 'Identificação de Dispositivo',
		contactInfo: 'Contato',
		quote: 'Estimativa',
		appointment: 'Agendamento',
		appointmentConfirmation: 'Confirmar Agendamento',
		appointmentConfirmed: 'Agendamento Confirmado',
		appointmentCancellation: 'Cancelar Agendamento',
		appointmentCancelled: 'Agendamento Cancelado',
		ticket: 'Chamado',
		ticketLookup: 'Busca de Chamado',
		rejected: 'Rejeitado'
	},
	validations: {
		required: 'Obrigatório',
		only_letters: 'Apenas letras são permitidas',
		only_numbers: 'Apenas números são permitidos',
		must_be_length: 'Deve conter {{length}} caracteres',
		min_length: 'Deve conter um mínimo de {{length}} caracter',
		invalid_email: 'Email inválido',
		invalid_only_whitespace: 'Não é permitido apenas espaços',
	},
	location: {
		use_my_location: 'Usar minha localização atual',
		geo_not_available: 'Geolocalização não disponível no seu navegador. Por favor digite seu endereço.',
		denied_access: 'Você negou acesso à sua localização.',
		no_locations_found: 'Nenhuma localização encontrada há pelo menos {{distance}} {{distance_unit}} de você.',
		please_update: 'Por favor mude sua localização ou <1>clique aqui</1> para atualizar',
		search_placeholder: 'Endereço, Cidade ou Código Postal',
		appt_time: 'Próximo Horário de Agendamento disponível',
		select_distance: '{{distance}} quilômetros'
	},
	customerInfo: {
		get_a_quote: 'Obter uma Estimativa'
	},
	rejected: {
		unable_to_accept: 'Não foi possível aceitar o dispositivo',
		thanks_for_completing: 'Obrigado por concluir a avaliação do seu dispositivo. No entanto, com base na avaliação que você concluiu, não podemos aceitar seu dispositivo.'
	},
	deviceIdentification: {
		identify_device: 'Identificar Dispositivo',
		identify_device_desc: 'Vamos começar! Para obter uma estimativa precisa do seu dispositivo, comece dizendo o que você tem.',
		other: 'Outro',
	},
	cancelSession: {
		title: 'Você tem certeza de que deseja cancelar esta sessão?',
		no: 'Não, desejo continuar',
		yes: 'Sim, desejo cancelar'
	},
	errorScreen: {
		try_again_message: 'Ou <1>clique aqui</1> para tentar novamente',
		error_code: 'Código de Erro:',
		error_message: 'Mensagem de Erro:',
		error_ref: 'Número de Ref de Erro:',
	},
	appointment: {
		title: 'Selecione um dia e horário para o agendamento',
		description: 'Recomendamos agendar, mas atendimentos por ordem de chegada são sempre bem-vindos!',
		no_available_appt: 'Desculpe, não há horários disponíveis para a data escolhida',
	},
	maintenance: {
		title: 'Estamos em manutenção.',
		description: 'Obrigado por nos visitar. Estamos realizando manutenção programada e estaremos de volta em breve',
	},
	appointmentConfirmation: {
		title: 'Confirme os detalhes do seu agendamento',
		day_time: 'Dia/Hora do Agendamento',
		contact_information: 'Informações de Contato',
		location: 'Localização',
		device: 'Dispositivo',
		get_directions: 'Traçar Rota',
		confirm_appointment: 'Confirmar Agendamento',
		change_day_time: 'Mudar Dia/Hora'
	},
	appointmentConfirmed: {
		title: 'Obrigado! Seu agendamento está marcado',
		get_another_quote_button_label: 'Fazer outro Orçamento',
		cancel_appointment: 'Cancelar Agendamento',
		view_summary_button_label: 'Ver Resumo',
	},
	appointmentCancellation: {
		title: 'Tem certeza de que deseja cancelar seu Agendamento?',
		keep_appointment: 'Manter Agendamento',
		cancel_appointment: 'Sim, Cancelar Agendamento',
	},
	appointmentCancelled: {
		title: 'Seu Agendamento foi cancelado',
		get_another_appointment_button_label: 'Fazer outro agendamento',
		get_another_quote_button_label: 'Fazer outro Orçamento',
		view_summary_button_label: 'Ver Resumo',
	},
	ticket: {
		title: 'Resumo do Serviço',
		description: 'Veja os detalhes do serviço abaixo',
		problem_description: 'Descrição do Problema',
		schedule_appointment: 'Criar Agendamento',
		cancel_appointment: 'Cancelar Agendamento',
		hello: 'Olá',
		ticket_number: 'Chamado #',
		notes: 'Observações',
		noNotes: 'Não há observações nesse momento.',
	},
	ticketLookup: {
		title: 'Pesquisar seu Chamado',
		description: 'Digite o número do seu Chamado ou da sua Autorização para receber um link seguro',
		input_placeholder: 'Número de Autorização',
		button_label: 'Enviar link seguro',
		valid_ticketID: 'Link seguro enviado',
		invalid_ticketID: 'Chamado não encontrado',
	},
	quote: {
		get_directions: 'Navegar para',
		repair: 'Reparo',
		device: 'Dispositivo',
		problem: 'Problema',
		location: 'Localização',
		schedule_appointment: 'Marcar um Agendamento',
		get_another_quote: 'Fazer outro orçamento',
	},
	breadcrumbs: {
		aria_label: 'navegação por categorias',
		identification: 'Identificação',
		appointment: 'Agendamento',
		contact: 'Contato',
		quote: 'Orçamento',
		location: 'Localização',
		confirmation: 'Confirmação',
		cancellation: 'Cancelamento',
	},
};
