import { faComment, faEnvelope, faMobile, faUserAlt, faIdBadge } from '@fortawesome/pro-light-svg-icons';

export function onPhoneChange(e) {
	e.preventDefault();
	const { value } = e.target;
	const phone = value.replace(/\D/g, '');
	if (phone.length > 6) {
		return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(
				6,
				10,
			)}`;
	} else if (phone.length > 3) {
			return `${phone.slice(0, 3)}-${phone.slice(3, 6)}`;
	} else {
		return phone;
	}
};

export function getFaIcon(name) {
	switch (name){
		case "faComment":
			return faComment;
		case "faUserAlt":
			return faUserAlt;
		case "faEnvelope":
			return faEnvelope;
		case "faMobile":
			return faMobile;
		case "faIdBadge":
			return faIdBadge;
		default:
			return faUserAlt;
	}
};
