"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setTicketInformation = function setTicketInformation(ticket) {
  return {
    type: _types["default"].SET_TICKET_INFORMATION,
    payload: ticket
  };
};

var setLocationInformation = function setLocationInformation(location) {
  return {
    type: _types["default"].SET_LOCATION_INFORMATION,
    payload: location
  };
};

var setServiceProgram = function setServiceProgram(repairType) {
  return {
    type: _types["default"].SET_SERVICE_PROGRAM,
    payload: repairType
  };
};

var resetTicketInformation = function resetTicketInformation() {
  return {
    type: _types["default"].RESET_TICKET_INFORMATION
  };
};

var _default = {
  setTicketInformation: setTicketInformation,
  resetTicketInformation: resetTicketInformation,
  setLocationInformation: setLocationInformation,
  setServiceProgram: setServiceProgram
};
exports["default"] = _default;