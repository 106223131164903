import api from '.';

export function createTicket(data) {
	return api.post('/ticket', data);
}

export function submitLookupNumber(lookupNumber) {
	return api.post('/ticket/sendQsiteLink', {
		uid: lookupNumber
	})
}

export function getTicketInfoFromToken(token) {
	return api.get('/ticket', { headers: { 'X-REPAIRQ-TOKEN': token } });
}

export function getTicketInfoFromIdAndLastName(pathname, search) {
	return api.get(pathname + search);
}

export default {
	createTicket,
	submitLookupNumber,
	getTicketInfoFromToken,
	getTicketInfoFromIdAndLastName,
}