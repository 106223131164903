"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var ticketState = {
  appointments: [],
  customer: {},
  gid: '',
  id: '',
  location: {},
  notesPrintable: [],
  status: '',
  status_label: '',
  status_updated_on: '',
  ticketDevices: [],
  ticket_edit_url: '',
  ticket_status_url: '',
  type: '',
  service_program: ''
};
var _default = ticketState;
exports["default"] = _default;