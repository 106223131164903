"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_APPOINTMENT: 'SET_APPOINTMENT',
  RESET_APPOINTMENT: 'RESET_APPOINTMENT'
};
exports["default"] = _default;