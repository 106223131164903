import { faReceipt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertBox, Button, Input, PageContainer, Title } from '@qsite/components';
import { appSettingsActions, useStore } from '@qsite/services';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { submitLookupNumber } from '../../services/api/ticket';

export default function TicketLookup() {
	const [lookupText, setLookupText] = useState('');
	const i18n = useTranslation();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [state, dispatch] = useStore();
	const { theme: customStyle } = state.qsiteSettings;

	const [alertBoxData, setAlertBoxData] = useState(null);

	function handleChange(e) {
		setLookupText(e.target.value);
	}

	const cleanupError = useCallback(() => {
		dispatch(appSettingsActions.setError({ showErrorScreen: false, errorResponse: null }));
	},[dispatch])
	
	useEffect(() => {
		return () => cleanupError();
	}, [cleanupError])

	function handleLookup() {
		cleanupError();
		setIsSubmitting(true);
		submitLookupNumber(lookupText)
			.then(res => {
				setAlertBoxData({
					type: 'info',
					message: i18n.t('ticketLookup.valid_ticketID')
				});
				setLookupText('');
				setIsSubmitting(false);				
			})
			.catch(err => {
				if (err.response.status === 404) {
					setAlertBoxData({
						type: 'error',
						message: i18n.t('ticketLookup.invalid_ticketID')
					});
					setIsSubmitting(false);					
				}
			});
	}
	
	return (
		<PageContainer flex>
			<Title title={i18n.t('ticketLookup.title')} customStyle={customStyle} />
			<p>{i18n.t('ticketLookup.description')}</p>
			
			{alertBoxData && 
				<AlertBox type={alertBoxData.type} role='alert'>
					{alertBoxData.message}
				</AlertBox>
			}

			<Input
				dataTestId='ticketLookupInput'
				placeholder={i18n.t('ticketLookup.input_placeholder')}
				icon={<FontAwesomeIcon icon={faReceipt} />}
				value={lookupText}
				onChange={handleChange}
			/>
			<Button
				label={i18n.t('ticketLookup.button_label')}
				onClick={handleLookup}
				disabled={!lookupText || isSubmitting}
				customStyle={customStyle}
			/>
		</PageContainer>
	)
};