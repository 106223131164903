"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_TICKET_INFORMATION: 'SET_TICKET_INFORMATION',
  SET_LOCATION_INFORMATION: 'SET_LOCATION_INFORMATION',
  RESET_TICKET_INFORMATION: 'RESET_TICKET_INFORMATION',
  SET_SERVICE_PROGRAM: 'SET_SERVICE_PROGRAM'
};
exports["default"] = _default;