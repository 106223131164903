import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import i18n from '../i18n';

export function iconFinder(option) {
	switch (option.label) {
		case i18n.t("general.no"):
			return faTimesCircle
		case i18n.t("general.yes"):
		case i18n.t("general.continue"):
			return faCheckCircle
		default:
			break;
	}
}