import { Button, Description, NAVIGATION_ACTION, PageContainer, Title } from '@qsite/components';
import { appSettingsActions, useStore } from '@qsite/services';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../utils/routesConfig';
import '../Pages.scss';
import './Appointment.scss';
import { LangContext } from '../../App';

export default function AppointmentCancelled() {
	const i18n = useTranslation();
	const [state, dispatch] = useStore();
	const { mapLanguages } = useContext(LangContext);
	const { theme: customStyle } = state.qsiteSettings;

	useEffect(() => {
		mapLanguages(state.qsiteSettings);
	}, []);

	function handleGetAnotherQuote() {
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.LOCATION, method: NAVIGATION_ACTION.PUSH }))
	}

	return (
		<PageContainer flex customStyle={customStyle}>
			<Title title={i18n.t('appointmentCancelled.title')} customStyle={customStyle} />
			<Description customStyle={customStyle}>
			<div className='confirmation-buttons'>
				{state.appSettings.initialLoad.path === APP_ROUTES.TICKET ?
					<Button
						buttonTheme='light'
						dataTestId='view-summary-button'
						label={i18n.t('appointmentCancelled.view_summary_button_label')}
						onClick={() => { dispatch(appSettingsActions.setProgress({ path: state.appSettings.initialLoad.path + state.appSettings.initialLoad.searchParam, method: NAVIGATION_ACTION.PUSH })) }}
						customStyle={customStyle}
					/> :
					<Button
						buttonTheme='light'
						dataTestId='get-another-quote-button'
						onClick={handleGetAnotherQuote}
						label={i18n.t('appointmentCancelled.get_another_quote_button_label')}
						customStyle={customStyle}
					/>}
				<Button
					dataTestId='get-another-appointment-button'
					label={i18n.t('appointmentCancelled.get_another_appointment_button_label')}
					onClick={() => dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT, method: NAVIGATION_ACTION.PUSH }))}
					customStyle={customStyle}
				/>
			</div>
			</Description>
		</PageContainer>
	)
}