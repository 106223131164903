"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var assessmentReducers = function assessmentReducers(state, action) {
  switch (action.type) {
    case _types["default"].SET_STEP:
      return {
        step: action.payload
      };

    case _types["default"].NEXT_STEP:
      return {
        step: state.step + 1
      };

    case _types["default"].RESET_STEP:
      return {
        step: 0
      };

    default:
      return state;
  }
};

var _default = assessmentReducers;
exports["default"] = _default;