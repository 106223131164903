"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_PROGRESS: 'SET_PROGRESS',
  SET_ERROR: 'SET_ERROR',
  SET_IS_MAINTENANCE: 'SET_IS_MAINTENANCE',
  SET_APPLICATION_MODAL: 'SET_APPLICATION_MODAL',
  SET_INITIAL_LOAD: 'SET_INITIAL_LOAD'
};
exports["default"] = _default;