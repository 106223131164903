"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encrypt = encrypt;
exports.decrypt = decrypt;
exports.sessionKeyName = void 0;

var _simpleCryptoJs = _interopRequireDefault(require("simple-crypto-js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var simpleCrypto = new _simpleCryptoJs["default"](process.env.REACT_APP_OBFUS_KEY);
var sessionKeyName = btoa('initialStateCombined');
exports.sessionKeyName = sessionKeyName;

function encrypt(object) {
  var encrypted = simpleCrypto.encrypt(object);
  return encrypted;
}

function decrypt(string) {
  var decrypted = simpleCrypto.decrypt(string);
  return decrypted;
}