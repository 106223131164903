"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

var _state = _interopRequireDefault(require("./state"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ticketReducers = function ticketReducers(state, action) {
  switch (action.type) {
    case _types["default"].SET_TICKET_INFORMATION:
      var nextTicketState = _objectSpread(_objectSpread({}, state), action.payload);

      return nextTicketState;

    case _types["default"].SET_LOCATION_INFORMATION:
      var nextTickState = _objectSpread(_objectSpread({}, state), {}, {
        location: _objectSpread({}, action.payload)
      });

      return nextTickState;

    case _types["default"].SET_SERVICE_PROGRAM:
      return _objectSpread(_objectSpread({}, state), {}, {
        service_program: action.payload
      });

    case _types["default"].RESET_TICKET_INFORMATION:
      return _state["default"];

    default:
      return state;
  }
};

var _default = ticketReducers;
exports["default"] = _default;