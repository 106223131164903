"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

var _state = _interopRequireDefault(require("./state"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var customerInformationReducers = function customerInformationReducers(state, action) {
  switch (action.type) {
    case _types["default"].SET_CUSTOMER_INFORMATION:
      var nextCustomerState = _objectSpread(_objectSpread({}, state), action.payload);

      return nextCustomerState;

    case _types["default"].RESET_CUSTOMER_INFORMATION:
      return _state["default"];

    case _types["default"].RESET_CUSTOMER_PERSONAL_INFORMATION:
      return _objectSpread(_objectSpread({}, _state["default"]), {}, {
        address: _objectSpread(_objectSpread({}, _state["default"].address), {}, {
          state: state.address.state,
          zip: state.address.zip,
          city: state.address.city
        })
      });

    default:
      return state;
  }
};

var _default = customerInformationReducers;
exports["default"] = _default;