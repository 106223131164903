"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var customerInformationState = {
  first_name: '',
  last_name: '',
  wl_customer_id: '',
  pri_phone: '',
  email: '',
  address: {
    address1: '',
    address2: '',
    state: '',
    zip: '',
    city: '',
    country: ''
  }
};
var _default = customerInformationState;
exports["default"] = _default;