"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setAppointment = function setAppointment(appointment) {
  return {
    type: _types["default"].SET_APPOINTMENT,
    payload: appointment
  };
};

var resetAppointment = function resetAppointment() {
  return {
    type: _types["default"].RESET_APPOINTMENT
  };
};

var _default = {
  setAppointment: setAppointment,
  resetAppointment: resetAppointment
};
exports["default"] = _default;