export default function ContactInfoConfig(qsiteFieldSettings = {}, showTermsModal) {
		const contactInfoObj = {
			formFields:[
			{
				type: 'text',
				id: 'firstName',
				name: 'firstName',
				required: 1,
				icon: 'faUserAlt',
				placeholder: 'customerInfo.first_name',
				dataTestId: 'firstName',
				validation: 'letters-only',
				gridSize: 2,
			},
			{
				type: 'text',
				id: 'lastName',
				name: 'lastName',
				required: 1,
				icon: 'faUserAlt',
				placeholder: 'customerInfo.last_name',
				dataTestId: 'lastName',
				validation: 'letters-only',
				gridSize: 2,
			},
			{
				type: 'email',
				id: 'email',
				name: 'email',
				required: 1,
				icon: 'faEnvelope',
				placeholder: 'customerInfo.email',
				dataTestId: 'email',
				validation: 'email',
				gridSize: 2,
			},
			{
				type: 'tel',
				id: 'phoneNumber',
				name: 'phoneNumber',
				required: 1,
				icon: 'faMobile',
				placeholder: 'customerInfo.phone_number',
				dataTestId: 'phoneNumber',
				validation: 'numbers-only',
				validate_phone_length: qsiteFieldSettings?.validate_phone_length ?? true,
				maxLength: 40,
				gridSize: 2,
			},
			{
				type: 'textarea',
				id: 'problemDescription',
				name: 'problemDescription',
				required: 1,
				icon: 'faComment',
				placeholder: 'customerInfo.problem_description',
				dataTestId: 'problemDescription',
				validation: 'all-valid-chars',
				rows: 4,
				gridSize: 1,
			},

			{
				type: 'blank',
				id: 'blankField',
				name: 'blankField',
				required: 0,
				icon: 'faComment',
				placeholder: '',
				dataTestId: 'blankField',
				gridSize: 2,
			},

			{
				type: 'radio',
				id: 'receiveNotifications',
				name: 'receiveNotifications',
				required: 1,
				icon: null,
				placeholder: 'customerInfo.receive_notifications',
				dataTestId: 'receiveNotifications',
				options: ['general.yes','general.no'],
				gridSize: 2,
			},

			{
				type: 'radio',
				id: 'receiveMarketing',
				name: 'receiveMarketing',
				required: 1,
				icon: null,
				placeholder: 'customerInfo.receive_marketing',
				dataTestId: 'receiveMarketing',
				options: ['general.yes','general.no'],
				gridSize: 2,
			},
		]};

		return contactInfoObj;
}
