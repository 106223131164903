"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NAVIGATION_ACTION = exports.CustomRoute = void 0;
//@ts-ignore
const services_1 = require("@qsite/services");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const CustomRoute = (props) => {
    const { component: Component, title, routesConfig } = props, routeProps = __rest(props, ["component", "title", "routesConfig"]);
    document.title = title;
    return (react_1.default.createElement(react_router_dom_1.Route, Object.assign({}, routeProps, { render: (props) => react_1.default.createElement(ResultComponent, Object.assign({}, props, { component: Component, routesConfig: routesConfig })) })));
};
exports.CustomRoute = CustomRoute;
var NAVIGATION_ACTION;
(function (NAVIGATION_ACTION) {
    NAVIGATION_ACTION[NAVIGATION_ACTION["REPLACE"] = 0] = "REPLACE";
    NAVIGATION_ACTION[NAVIGATION_ACTION["PUSH"] = 1] = "PUSH";
    NAVIGATION_ACTION[NAVIGATION_ACTION["POP"] = 2] = "POP";
})(NAVIGATION_ACTION = exports.NAVIGATION_ACTION || (exports.NAVIGATION_ACTION = {}));
const ResultComponent = (props) => {
    const { component: Component, history, location: { pathname, search: urlSearchParam }, routesConfig: { confirmModalResolver, hasRouteAccess } } = props;
    const [{ appSettings: { progress } }, dispatch] = services_1.useStore();
    const firstRender = services_1.useFirstRender();
    const historyUpdate = react_1.useCallback((progress) => {
        const path = progress.searchParam != null ? `${progress.path}${progress.searchParam}` : progress.path;
        switch (progress.method) {
            case NAVIGATION_ACTION.REPLACE:
                history.replace(path, progress.state);
                break;
            case NAVIGATION_ACTION.PUSH:
                history.push(path, progress.state);
                break;
            case NAVIGATION_ACTION.POP:
                history.goBack();
            default:
                history.push(path, progress.state);
        }
    }, [history]);
    // App regular navigation - setting progress and updating route
    react_1.useEffect(() => {
        if (!firstRender && (pathname !== progress.path || urlSearchParam != progress.searchParam)) {
            historyUpdate(progress);
        }
    }, [firstRender, historyUpdate, progress]);
    // Browser navigation - trying to update URL or going back and forth on browser
    react_1.useEffect(() => {
        if (pathname !== progress.path || urlSearchParam != progress.searchParam) {
            if (hasRouteAccess(progress.path, pathname)) {
                dispatch(services_1.appSettingsActions.setProgress({ path: pathname, method: history.action, searchParam: urlSearchParam }));
            }
            else {
                dispatch(services_1.appSettingsActions.setProgress({ path: progress.path, method: NAVIGATION_ACTION.REPLACE, searchParam: urlSearchParam }));
            }
        }
    }, [dispatch, pathname]);
    react_1.useEffect(() => {
        const unblockHistory = props.history.block((location) => {
            let shouldShowModal = null;
            const modalType = confirmModalResolver(location.pathname, progress.path);
            if (modalType !== 'NO_SHOW') {
                shouldShowModal = location.pathname;
            }
            return shouldShowModal;
        });
        return () => unblockHistory();
    }, [progress, props.history]);
    return pathname === progress.path ? (react_1.default.createElement(Component, Object.assign({}, props))) : null;
};
