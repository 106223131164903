"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useFirstRender", {
  enumerable: true,
  get: function get() {
    return _useFirstRender["default"];
  }
});
Object.defineProperty(exports, "useReset", {
  enumerable: true,
  get: function get() {
    return _useReset["default"];
  }
});

var _useFirstRender = _interopRequireDefault(require("./useFirstRender"));

var _useReset = _interopRequireDefault(require("./useReset"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }