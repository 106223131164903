import React from "react";
import ReactDOM from "react-dom";
import CancelSession from './CancelSession';
import { theme } from '../../App';
import { ThemeProvider } from "styled-components";

const UserConfirmation = (message, callback) => {
	let modalParentContainer = document.getElementById("custom-confirmation-navigation");
	if (modalParentContainer == null) {
		modalParentContainer = document.createElement("div");
		modalParentContainer.setAttribute("id", "custom-confirmation-navigation");
	}
	document.body.appendChild(modalParentContainer);

	const closeModal = (callbackState) => {
		ReactDOM.unmountComponentAtNode(modalParentContainer);
		callback(callbackState);
	};
	ReactDOM.render(
		<ThemeProvider theme={theme}>
			<CancelSession callback={closeModal} />
		</ThemeProvider>
		, modalParentContainer);
};

export default UserConfirmation;