import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Description, Grid, InfoCard, NAVIGATION_ACTION, PageContainer, Title } from '@qsite/components';
import { appSettingsActions, customerInformationActions, deviceActions, locationActions, appointmentActions, ticketActions, useStore, useReset } from '@qsite/services';
import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getTicketInfoFromIdAndLastName, getTicketInfoFromToken } from '../../services/api/ticket';
import { APP_ROUTES } from '../../utils/routesConfig';

export default function Ticket() {
	const i18n = useTranslation();
	const location = useLocation();
	const [state, dispatch] = useStore();
	const { resetQsiteInitialState } = useReset();
	const [activePage, setActivePage] = useState('');
	const [ticketInfo, setTicketInfo] = useState({
		id: '',
		status_updated_on_str: '',
		notesPrintable: [],
		status_label: '',
		balance_due: ''
	});
	const { theme: customStyle } = state.qsiteSettings;

	function handleChangeAppointment(e) {
		e.preventDefault()
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT, method: NAVIGATION_ACTION.PUSH }))
	}

	function handleCancelAppointment() {
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT_CANCELLATION, method: NAVIGATION_ACTION.PUSH }))
	}

	function handleScheduleAppointment() {
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT, method: NAVIGATION_ACTION.PUSH }))
	}
	

	const PAGES = {
		TOKEN_PAGE: 'tokenPage',
		ID_PAGE: 'idPage'
	}
	
	useEffect(
		useCallback(() => {
			// Ticket page has 2 main uses:
			// 1: user navigates using the generated token (activePage = 'tokenPage')
			// 2: user navigates using the ticket ID + last name (activePage = 'idPage')
			resetQsiteInitialState();
			const URLparams = new URLSearchParams(location.search);
			const token = URLparams.get('token');
			const lastName = URLparams.get('last_name');

			if (token !== null) {
				getTicketInfoFromToken(token)
					.then(res => {
						let {  customer, location, ticketDevices, appointment, appointments } = res.data.data.ticket;
						customer = {
							first_name: customer.first_name,
							last_name: customer.last_name,
							wl_customer_id: customer.wl_customer_id,
							pri_phone: customer.pri_phone,
							email: customer.email,
							address: {
								address1: customer.address1,
								address2: customer.address2,
								state: customer.state,
								zip: customer.zip,
								city: customer.city,
								country: customer.country,
							}
						}
						appointment = {
							time: new Date(appointments[appointments.length-1]?.date_scheduled).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
							day: new Date(appointments[appointments.length-1]?.date_scheduled).toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year:'numeric' }).replace(',', ' '),
							appointment_id: appointments[appointments.length-1]?.appointment_id,
							rawTime: appointments[appointments.length-1]?.date_scheduled
						}
						ticketDevices = {
							name: ticketDevices[ticketDevices.length-1].name,
							problem_description: ticketDevices[ticketDevices.length-1].problem_description_str
						}				
						
						dispatch(deviceActions.setDevice(ticketDevices));
						dispatch(customerInformationActions.setCustomerInformation(customer));
						if(appointments[appointments.length-1]?.status !== "Cancelled"){
							dispatch(appointmentActions.setAppointment(appointment))}
						dispatch(ticketActions.setTicketInformation(res.data.data.ticket));
						dispatch(locationActions.setLocation(location))
						dispatch(appSettingsActions.setInitialLoad({ path: APP_ROUTES.TICKET, searchParam:`?token=${token}` }));
					})
				setActivePage(PAGES.TOKEN_PAGE);
			} else if (lastName !== null) {
				getTicketInfoFromIdAndLastName(location.pathname, location.search)
					.then(res => {
						let { location } = res.data.data.ticket;
						setTicketInfo({
							...ticketInfo,
							id: res.data.data.ticket.id,
							status_updated_on_str: res.data.data.ticket.status_updated_on_str,
							notesPrintable: res.data.data.ticket.notesPrintable,
							status_label: res.data.data.ticket.status_label,
							balance_due: '$150.00',
						});
						dispatch(locationActions.setLocation(location));
						dispatch(deviceActions.setDevice({ name: 'iPhone 6 Silver 128Gb' }));
						dispatch(customerInformationActions.setCustomerInformation({ first_name: 'Cleberson' }))
					});
				setActivePage(PAGES.ID_PAGE);
			}
		}, [PAGES.ID_PAGE, PAGES.TOKEN_PAGE, dispatch, location.pathname, location.search, resetQsiteInitialState, ticketInfo]),
	[]);

	return (
		<PageContainer flex>

			{/* First Page */}
			{activePage === PAGES.TOKEN_PAGE &&
				<>
					<Title title={i18n.t('ticket.title')} customStyle={customStyle} />
					<Description>{i18n.t('ticket.description')}</Description>
					<Grid gridTemplateColumns='1fr 1fr' mobileGridTemplateColumns='1fr' gridGap='0px' gridWidth='500px'>
						{!_.isEmpty(state.appointment.appointment_id)  &&
							<InfoCard title={i18n.t('appointmentConfirmation.day_time')}>
								<p>{state.appointment.day} at {state.appointment.time}</p>
								<div className='change-information-container'>
									<FontAwesomeIcon icon={faCalendarAlt} />
									<a href="#" onClick={(e) => handleChangeAppointment(e)}>{i18n.t('appointmentConfirmation.change_day_time')}</a>
								</div>
							</InfoCard>
						}
						<InfoCard title={i18n.t('appointmentConfirmation.contact_information')}>
							<p>{state.customerInformation.first_name} {state.customerInformation.last_name}</p>
							<p>{state.customerInformation.pri_phone}</p>
							<p>{state.customerInformation.email}</p>
						</InfoCard>
						<InfoCard title={i18n.t('appointmentConfirmation.location')}>
							<p>{state.location.short_name}</p>
							<p>{state.location.address1}</p>
							<p>{state.location.city}, {state.location.state} {state.location.zip}</p>
							<p>{state.location.pri_phone}</p>
						</InfoCard>
						<InfoCard title={i18n.t('appointmentConfirmation.device')}>
							<p>{state.device.name}</p>
						</InfoCard>
						<InfoCard title={i18n.t('ticket.problem_description')}>
							<p>{state.device.problem_description}</p>
						</InfoCard>
					</Grid>
					<div className='schedule-buttons'>
					{!_.isEmpty(state.appointment.appointment_id) ? (
						<Button
							buttonTheme='light'
							dataTestId='cancel-appointment-button'
							label={i18n.t('ticket.cancel_appointment')}
							onClick={handleCancelAppointment}
						/>) : (
							<Button
							dataTestId='schedule-appointment-button'
							label={i18n.t('ticket.schedule_appointment')}
							onClick={handleScheduleAppointment}
							customStyle={customStyle}
						/>)}
					</div>
				</>}
				

			{/* Second Page */}
			{activePage === PAGES.ID_PAGE &&
				<>
					<Title title={`${i18n.t('ticket.hello')}, ${state.customerInformation.first_name}!`} customStyle={customStyle} />
					<Grid gridTemplateColumns={'repeat(2, minmax(150px, max-content))'}
						mobileGridTemplateColumns={'repeat(2, minmax(120px, max-content))'}
						gridGap='0px'
						gridWidth='500px'>
						<InfoCard title={`${i18n.t('ticket.ticket_number')} ${ticketInfo.id}`}>
							<p>{ticketInfo.status_label}</p>
							<p>{ticketInfo.status_updated_on_str}</p>
							<p>{ticketInfo.balance_due}</p>
						</InfoCard>
						<InfoCard title={i18n.t('appointmentConfirmation.location')}>
							<p>{state.location.short_name}</p>
							<p>{state.location.address1}</p>
							<p>{state.location.city}, {state.location.state}</p>
							<p>{state.location.pri_phone}</p>
						</InfoCard>
						<InfoCard title={i18n.t('appointmentConfirmation.device')}>
							<p>{state.device.name}</p>
						</InfoCard>
						<InfoCard title={i18n.t('ticket.notes')}>
							<p>{_.isEmpty(ticketInfo.notesPrintable.length) ? i18n.t('ticket.noNotes') : ticketInfo.notesPrintable}</p>
						</InfoCard>
					</Grid>
				</>}

		</PageContainer>
	)
}