import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from "styled-components";
import './Calendar.scss';

const StyledCalendar = styled.div`
	max-width: 150px;
`

export default function Calendar(props) {
	const { currentDate, setCurrentDate } = props;
		

	function handleChange(date) {
		setCurrentDate(date);
	};

	return (
		<StyledCalendar>
			<DatePicker
				selected={currentDate}
				onChange={handleChange}
				className={'date_input'}
				minDate={new Date()}
			/>
		</StyledCalendar>
	);
}
