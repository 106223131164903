import { faCalendarAlt, faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, InfoCard, NAVIGATION_ACTION, PageContainer, Title, Breadcrumbs, Description } from '@qsite/components';
import { appointmentActions, appSettingsActions, useStore } from '@qsite/services';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAppointment } from '../../services/api/appointment';
import { APP_ROUTES, breadcrumbsNavigationConfig } from '../../utils/routesConfig';
import '../Pages.scss';
import './Appointment.scss';
import { LangContext } from '../../App';

export default function AppointmentConfirmation() {
	const [state, dispatch] = useStore();
	const i18n = useTranslation();
	const displayBreadcrumbs = Boolean(state.qsiteSettings.theme?.breadcrumbs ?? false);
	const { mapLanguages } = useContext(LangContext);
	const { theme: customStyle } = state.qsiteSettings;

	useEffect(() => {
		mapLanguages(state.qsiteSettings);
	}, []);

	const breadcrumb = 	[
		{ name: i18n.t('breadcrumbs.location'), pathToNavigate: APP_ROUTES.LOCATION },
		{ name: i18n.t('breadcrumbs.identification'), pathToNavigate: APP_ROUTES.DEVICE_ID },
		{ name: i18n.t('breadcrumbs.contact'), pathToNavigate: APP_ROUTES.CUSTOMER },
		{ name: i18n.t('breadcrumbs.quote'), pathToNavigate: APP_ROUTES.QUOTE },
		{ name: i18n.t('breadcrumbs.appointment'), pathToNavigate: APP_ROUTES.APPOINTMENT },
		{ name: i18n.t('breadcrumbs.cancellation') },
	]

	function handleGetDirectionsClick(e) {
		const googleMapsUrl = `https://www.google.com/maps/place/${state.location.address1}+${state.location.city}+${state.location.state}`;
		e.preventDefault();
		e.stopPropagation();
		return window.open(googleMapsUrl, '_blank');
	}

	function handleCancelAppointment() {
		deleteAppointment(state.appointment.appointment_id)
			.then(() => {
				dispatch(appointmentActions.resetAppointment());
				dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT_CANCELLED, method: NAVIGATION_ACTION.PUSH }));
			})
			.catch(error => console.error(error));
	}

	function handleChangeAppointment(e) {
		e.preventDefault();
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT, method: NAVIGATION_ACTION.PUSH }))
	}


	return (
		<PageContainer flex customStyle={customStyle}>
			{ displayBreadcrumbs &&
				(
					<Breadcrumbs
						type={'navigation'}
						crumbs={breadcrumb}
						navigationConfig={breadcrumbsNavigationConfig}
						translatedKeys={{ ariaLabel: i18n.t('general.breadcrumbs_aria_label') }}
						customStyle={customStyle}
					/>
				)
			}
			<Title title={i18n.t('appointmentCancellation.title')} customStyle={customStyle} />
			<Description customStyle={customStyle}>
			<Grid gridTemplateColumns={'1fr 1fr'} mobileGridTemplateColumns={'1fr'} gridGap='0px' gridWidth='500px'>
				<InfoCard title={i18n.t('appointmentConfirmation.day_time')} titleTestId="confirmation-appointment"
				customStyle={customStyle}>
					<p>
						{state.appointment.day} at {state.appointment.time}
					</p>
					<div className='change-information-container'>
						<FontAwesomeIcon icon={faCalendarAlt} />
						<a href="#" onClick={(e) => handleChangeAppointment(e)}>
							{i18n.t('appointmentConfirmation.change_day_time')}
						</a>
					</div>
				</InfoCard>
				<InfoCard title={i18n.t('appointmentConfirmation.contact_information')} titleTestId="confirmation-contact-information"
				customStyle={customStyle}>
					<p>
						{state.customerInformation.first_name}{' '}
						{state.customerInformation.last_name}
					</p>
					<p>{state.customerInformation.pri_phone}</p>
					<p>{state.customerInformation.email}</p>
				</InfoCard>
				<InfoCard title={i18n.t('appointmentConfirmation.location')} titleTestId="confirmation-location"
				customStyle={customStyle}>
					<p>{state.location.short_name}</p>
					<p>{state.location.address1}</p>
					<p>
						{state.location.city}, {state.location.state}
					</p>
					<p>{state.location.zip}</p>
					<div className='change-information-container'>
						<FontAwesomeIcon icon={faMapMarkerAlt} />
						<a href="#" onClick={(e) => handleGetDirectionsClick(e)} >{i18n.t('appointmentConfirmation.get_directions')}</a>
					</div>
				</InfoCard>
				<InfoCard title={i18n.t('appointmentConfirmation.device')} titleTestId="confirmation-device" customStyle={customStyle}>
					<p>{state.device.parent?.name_friendly || state.device.parent?.name || state.device.name}</p>
				</InfoCard>
			</Grid>
			<div className='confirmation-buttons'>
				<Button
					buttonTheme='light'
					dataTestId='confirm-cancel-appointment-button'
					onClick={handleCancelAppointment}
					label={i18n.t('appointmentCancellation.cancel_appointment')}
					customStyle={customStyle}
				/>
				<Button
					dataTestId='keep-appointment-button'
					label={i18n.t('appointmentCancellation.keep_appointment')}
					onClick={() => dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.APPOINTMENT_CONFIRMED, method: NAVIGATION_ACTION.PUSH }))}
					customStyle={customStyle}
				/>
			</div>
			</Description>
		</PageContainer>
	);
}
