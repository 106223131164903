import api from '.';

export function getAppSettings(lang = 'en_US') {
  return api.get('appSetting/details?option=qsite', { params: { lang } });
}

export function deleteSession() {
	return api.delete(`session`, {withCredentials: true})
}

export function getValidationRules() {
  return api.get('validationRule');
}