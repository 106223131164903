import { ErrorScreen } from '@qsite/components';
import { appSettingsActions, qsiteSettingsActions, useStore } from '@qsite/services';
import { useReset } from '@qsite/services/lib/hooks';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CustomRouteQSITE } from './components/CustomRouteQSITE';
import Appointment from './pages/Appointment/Appointment';
import AppointmentCancellation from './pages/Appointment/AppointmentCancellation';
import AppointmentCancelled from './pages/Appointment/AppointmentCancelled';
import AppointmentConfirmation from './pages/Appointment/AppointmentConfirmation';
import AppointmentConfirmed from './pages/Appointment/AppointmentConfirmed';
import ContactInfo from './pages/ContactInfo/ContactInfo';
import DeviceIdentification from './pages/Device/DeviceIdentification/DeviceIdentification';
import Location from './pages/Location/Location';
import Maintenance from './pages/Maintenance/Maintenance';
import Quote from './pages/Quote/Quote';
import Rejected from './pages/Rejected/Rejected';
import Ticket from "./pages/Ticket/Ticket";
import TicketLookup from "./pages/TicketLookup/TicketLookup";
import api from './services/api';
import { getAppSettings, getValidationRules } from './services/api/appSettings';
import { APP_ROUTES } from './utils/routesConfig';
import { LangContext } from './App';
import { GTMContext } from './App';

export default function Routes() {
	const [{ appSettings: { isMaintenance }, }, dispatch,] = useStore();
	const { resetQsiteInitialState } = useReset();
	const { appLang } = useContext(LangContext);
	const { gtmInitialize } = useContext(GTMContext);
	const { search } = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);

	// Initial api call to load application config
	useEffect(useCallback(() => {
		// Fetch app settings
		function fetchAppSettings() {
			getAppSettings(appLang).then(response => {
				dispatch(qsiteSettingsActions.setQsiteSettings(response.data.data.qsite));
				let gtmId = response.data.data.qsite?.gtmContainerId;
				if(gtmId) {
					gtmInitialize(gtmId);
				}
				grabAndStoreURLCustomVariables(response.data.data.qsite);
			})
		}

		function fetchValidationRules() {
			getValidationRules().then(response => {
				dispatch(qsiteSettingsActions.setValidationRules(response.data.data?.validationRule));
			})
		}

		function grabAndStoreURLCustomVariables(qsiteResponse){
			let ticketCustomAttributes = qsiteResponse?.ticketCustomAttributes;

			if(!ticketCustomAttributes) return;

			let urlQueryValues = {};
			let foundQueryValues = false;
			for(let [key, value] of searchParams.entries()) {
				urlQueryValues[key] = value;
				foundQueryValues = true;
			}

			if(!foundQueryValues) return;

			let ret = {};
			let matchFound = false;
			for(let i = 0; i < ticketCustomAttributes.length; i++){
				if(typeof urlQueryValues[ticketCustomAttributes[i]] !== 'undefined'){
					ret[ticketCustomAttributes[i]] = urlQueryValues[ticketCustomAttributes[i]];
					matchFound = true;
				}
			}

			if(!matchFound) return;
			localStorage.setItem('urlQueryValues', JSON.stringify(ret));
		}

		fetchAppSettings();
		fetchValidationRules();
	}, [resetQsiteInitialState, dispatch]), [dispatch, appLang]);

	// Interceptors on api calls to treat errors and maintenance mode
	api.interceptors.response.use(response => {
		// Any status code that lie within the range of 2xx cause this function to trigger
		if (isMaintenance) dispatch(appSettingsActions.setIsMaintenance(false));
		return response;
	}, error => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		if (error.response.data?.message?.includes('Compatibility not found')) return error

		if (error.response?.status === 503) {
			dispatch(appSettingsActions.setIsMaintenance(true));
		} else {
			// If api call for Ticket, we want the error displayed on the ticket lookup screen and not on generic Error Screen
			if (error.config.url === "/ticket/sendQsiteLink" && error.response.data?.message !== 'uid required') {
				dispatch(appSettingsActions.setError({ showErrorScreen: false, errorResponse: error.response }));
			} else {
				dispatch(appSettingsActions.setError({ showErrorScreen: true, errorResponse: error.response }));
			}
		}
		return Promise.reject(error);
	});

	const basename = process.env.NODE_ENV === 'development' ? '/' : '/qsite';
	return (
			<ErrorBoundary>
				{isMaintenance ?
					<Route path='/' component={Maintenance} />
					:
					<Switch>
						<Route path='/' exact>
							<Redirect to={APP_ROUTES.LOCATION} />
						</Route>
						<Route path="/location/:locationId">
							<DeviceIdentification />
						</Route>
						<Route path="/appt">
							<AppointmentConfirmed />
						</Route>
						{/* main flow */}
						<CustomRouteQSITE path={APP_ROUTES.LOCATION} component={Location} />
						<CustomRouteQSITE path={APP_ROUTES.DEVICE_ID} component={DeviceIdentification} />
						<CustomRouteQSITE path={APP_ROUTES.CUSTOMER} component={ContactInfo} />
						<CustomRouteQSITE path={APP_ROUTES.QUOTE} component={Quote} />

						{/* appointment module */}
						<CustomRouteQSITE exact path={APP_ROUTES.APPOINTMENT} component={Appointment} />
						<CustomRouteQSITE path={APP_ROUTES.APPOINTMENT_CONFIRMATION} component={AppointmentConfirmation} />
						<CustomRouteQSITE path={APP_ROUTES.APPOINTMENT_CONFIRMED} component={AppointmentConfirmed} />
						<CustomRouteQSITE path={APP_ROUTES.APPOINTMENT_CANCELLATION} component={AppointmentCancellation} />
						<CustomRouteQSITE path={APP_ROUTES.APPOINTMENT_CANCELLED} component={AppointmentCancelled} />

						{/* ticket module */}
						<CustomRouteQSITE exact path={APP_ROUTES.TICKET} component={Ticket} />
						<CustomRouteQSITE path={APP_ROUTES.TICKET_LOOKUP} component={TicketLookup} />

						{/* exception flow */}
						<CustomRouteQSITE path={APP_ROUTES.REJECTED} component={Rejected} />
						<Route path='*'>
							<Redirect to='/' />
						</Route>
					</Switch>
				}
			</ErrorBoundary>
	);
}

function ErrorBoundary({ children }) {
	const [state] = useStore();
	const error = state.appSettings.error;
	const i18n = useTranslation();

	if (error.showErrorScreen) {
		return (<>
			<ErrorScreen pageToRedirect={APP_ROUTES.LOCATION} tryAgain
				translatedKeys={{
					tryAgainMessage: 'errorScreen.try_again_message',
					errorCode: i18n.t('errorScreen.error_code'),
					errorMessage: i18n.t('errorScreen.error_message'),
					errorRef: i18n.t('errorScreen.error_ref')
				}} />
		</>);
	} else {
		return children;
	}
}
