"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var qsiteSettingsState = {
  "appointments": {
    "confirmation": {
      "title": "Thank You! Your appointment is scheduled",
      "description": "Please ensure you have your device ready by your appointment time for same day repair and collection."
    },
    "default_updated_status": "3",
    "time": {
      "description": "Here are your available screen replacement options. Please be available at your location for the entire time you select.",
      "title": "Select an appointment day and time",
      "disclaimer": "Approximate time:\nPhone screen replacement - 30 min\nTablet screen replacement - 1 hour\n"
    },
    "block": "window",
    "active": "0",
    "unset_assignee": "0"
  },
  "category": {
    "request_types": {
      "repair": {
        "title": "Select Your Device"
      }
    }
  },
  "compatible_service": {
    "title": "What problem do you have with your device?",
    "active": "0",
    "show_images": "0",
    "filter": "is_labor=1"
  },
  "error": {
    "description": "For further assistance, please contact support",
    "title": "Oops! Something went wrong..."
  },
  "location": {
    "description": "Select the location you wish to receive service from",
    "title": "Pick Your Location"
  },
  "locations": {
    "description": "Enter an address, postal / zip code, or city to begin your search",
    "distance": "25",
    "distance_unit": "mi",
    "limit": "5",
    "title": "Let's get started"
  },
  "quotes": {
    "allow_catalog_price": "1",
    "contact": {
      "description": "Please tell us how to get in touch with you",
      "title": "Additional Information",
      "validate_phone_length": true
    },
    "price_available": {
      "description": "Disclaimer: This price includes a service fee and may vary once we inspect your device.",
      "disclaimer": "",
      "title": "Estimate price for your device’s screen replacement"
    },
    "price_unavailable": {
      "description": "We will reach out to you soon with more information, or you can schedule an appointment",
      "title": "Your request has been received",
      "disclaimer": "If you need immediate assistance, please call or visit our location"
    },
    "show_prices": "0",
    "price_title": "Screen Replacement",
    "price_description": "Device",
    "button_yes": "Confirm",
    "button_no": "Cancel"
  },
  "request_types": {
    "repair": {
      "title": "Identify your device",
      "active": "0",
      "show_images": "0",
      "category": "1"
    },
    "title": "How can we help you today?",
    "image_placeholder": "https://static.repairq.io/repairq/img/stock/placeholder.svg",
    "active": "0",
    "sale": {
      "active": "0",
      "show_images": "0"
    },
    "trade": {
      "active": "0",
      "show_images": "0"
    }
  },
  "terms": {
    "content": "Replace with your terms here...",
    "enabled": "1"
  },
  "theme": {
    "color": {
      "primary": "#0091CD",
      "secondary": "#27A9E1",
      "success": "#58B957",
      "error": "#DD5044"
    },
    "breadcrumbs": "0"
  },
  "ticket": {
    "title": "Service Summary",
    "description": "View your service details below\r\n",
    "lookup": {
      "title": "Ticket Lookup",
      "description": "Enter your ticket or authorization number below to receive a secure link",
      "help_text": "Help Text"
    }
  },
  "trade_in": {
    "questions": [{
      "order": "0",
      "title": "Device assessment",
      "description": "You’ll need to answer a few questions about your device for us to determine whether your device is eligible for a screen replacement. </br></br></br> Disclaimer: You may be charged a fee if the device and condition physically presented to the technician does not match the description provided in your responses.",
      "answers": [{
        "label": "Next",
        "order": "0",
        "grade_ex_all": null
      }]
    }, {
      "order": "1",
      "title": "Is your device water damaged?",
      "description": "Liquid damage may not be visible on the outside. Visible liquid damage can appear as bubbles under your screen, corrosion or discoloration inside the charge port.",
      "answers": [{
        "label": "Yes",
        "order": "0",
        "grade_ex_all": true
      }, {
        "label": "No",
        "order": "1",
        "grade_ex_all": false
      }]
    }, {
      "order": "2",
      "title": "Is your device bent?",
      "description": "Remove your device’s protective case to check for any bends on the device.",
      "answers": [{
        "label": "Yes",
        "order": "0",
        "grade_ex_all": true
      }, {
        "label": "No",
        "order": "1",
        "grade_ex_all": false
      }]
    }, {
      "order": "3",
      "title": "Does your Home button or Touch ID work properly?",
      "description": "If you have an iPhone 7, 8 or iPhone SE (2020), does the Home button show signs of physical damage, such as a crack or chip on the button? Does Touch ID on the Home button function normally? If either of these issues are present, please select Yes.",
      "answers": [{
        "label": "Yes",
        "order": "0",
        "grade_ex_all": false
      }, {
        "label": "No",
        "order": "1",
        "grade_ex_all": true
      }, {
        "label": "My device doesn't have a Home button",
        "order": "2",
        "grade_ex_all": null
      }]
    }]
  },
  "customer_update_allowed": "1",
  "customer_allowed_fields": {
    "additional_instructions": "1",
    "address1": "1",
    "address2": "1",
    "city": "1",
    "state": "1",
    "wl_customer_id": "1"
  },
  "service_program": {
    "telstra-cty": {
      "skip_appointment_picker": "0",
      "eligibility": "1",
      "show_repair_location": "0",
      "show_shipping_address": "0",
      "confirmation": {
        "title": "Confirm your screen replacement",
        "description": "Please carefully review the information below and confirm that it’s correct. Select Confirm if your details are correct and you’ll receive your screen replacement reference number."
      },
      "congratulations": {
        "button_label": "Cancel",
        "description": "Your screen replacement is booked. You'll receive an email shortly with more information.",
        "disclaimer": "<p>Disclaimer: You may be charged a $59 fee if you are not at the elected address when the technician arrives or if your device is not in the condition stated.</p><p>Before your appointment, you should:</p>\n<p>1. Please remove the passcode or PIN from your device.\n2. Please back up your device.\n3. Please ensure your device is fully charged at the time of your appointment.</p>\nYou’ll receive a reminder email approximately 24 hours prior to your appointment.\nYou’ll also receive an email when the technician is on their way to your location.</p>",
        "subtitle": "Reference Number",
        "title": "Thank you"
      },
      "edit_contact_allowed": "0",
      "edit_device_allowed": "0"
    },
    "telstra-mi": {
      "skip_appointment_picker": "0",
      "show_repair_location": "0",
      "show_shipping_address": "0",
      "confirmation": {
        "title": "Confirm your screen replacement mailing details",
        "description": "Please carefully review your information provided to confirm that it’s correct."
      },
      "congratulations": {
        "button_label": "Cancel",
        "description": "You’ve confirmed that you’d like to send in your device to have its screen replaced. You’ll receive an email shortly with more information.",
        "disclaimer": "<p>Your satchel will arrive within 2 business days. Please use the return satchel to send your device to us. Once we receive your device, we’ll replace your screen and return the device to you within 2 business days.\nBefore sending your device to us, please make sure you:\n1. Remove the passcode or PIN from your device.\n2. Remove any protective case from your device.\n3. Back up your device.\n4. Please ensure your device is fully charged</p>",
        "subtitle": "Your reference number is",
        "title": "Thank you"
      },
      "edit_contact_allowed": "0",
      "edit_device_allowed": "0",
      "location_id": "10"
    }
  },
  "service_location": {
    "title": "Your location",
    "description": "Enter the address where you’d like the screen replaced. This can be your home or office address."
  },
  "shipping_location": {
    "title": "Shipping address",
    "description": "We’ll send you a satchel for you to put your device in to send back to us. Enter your address you’d like us to send the satchel to."
  },
  "repair": {
    "enumerate_questions": "0"
  },
  "device_info": {
    "title": "Device Information",
    "description": "Please provide the IMEI number and Telstra Service Number for the device requiring a new screen."
  },
  "device_confirmation": {
    "title": "Confirm your device details",
    "description": "To replace your screen, we need you to confirm details for your device below.",
    "disclaimer": "Disclaimer: You may be charged a fee if the device and condition you physically present to the technician does not match the device listed below."
  },
  "rejected": {
    "title": "We are currently unable to replace your screen",
    "description": "Please contact Telstra to ensure your details are correct. Once your details have been updated, please proceed to submit a new screen replacement request.",
    "button_label": "Okay"
  },
  "contact_information": {
    "title": "Let’s confirm your contact details",
    "description": "We need to confirm your contact details to complete your order. We’ll ask you to enter in your name, contact details and preferred location details. Please Note, you must be over the age of 18 to perform a Screen Replacement service."
  }
};
var _default = qsiteSettingsState;
exports["default"] = _default;