"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  SET_LOCATION: 'SET_LOCATION',
  RESET_LOCATION: 'RESET_LOCATION'
};
exports["default"] = _default;