"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var deviceState = {
  id: null,
  name: null,
  imei: '',
  sku: null,
  grades: [{
    id: 'U',
    order: '0',
    sku_modifier: 'GU'
  }, {
    id: 'R',
    order: '1',
    sku_modifier: 'GR'
  }],
  gradesHistory: [],
  cost: null,
  telstraServiceNumber: '',
  isSaved: false,
  isWearable: null,
  parentId: null
};
var _default = deviceState;
exports["default"] = _default;