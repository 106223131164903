"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = _interopRequireDefault(require("./types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setLocation = function setLocation(location) {
  return {
    type: _types["default"].SET_LOCATION,
    payload: location
  };
};

var resetLocation = function resetLocation() {
  return {
    type: _types["default"].RESET_LOCATION
  };
};

var _default = {
  setLocation: setLocation,
  resetLocation: resetLocation
};
exports["default"] = _default;